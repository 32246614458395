/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SettingsPreferenceEnum } from './SettingsPreferenceEnum';
import type { TKSettingsPayPeriodKindEnum } from './TKSettingsPayPeriodKindEnum';
import type { Weekdays } from './Weekdays';

export type Settings = {
    firm_id: string;
    qualifications?: SettingsPreferenceEnum;
    location_max?: SettingsPreferenceEnum;
    location_max_distance?: number;
    location_min?: SettingsPreferenceEnum;
    location_min_distance?: number;
    availability?: SettingsPreferenceEnum;
    overtime?: SettingsPreferenceEnum;
    pay_range?: SettingsPreferenceEnum;
    customer_experience?: SettingsPreferenceEnum;
    post_experience?: SettingsPreferenceEnum;
    buffer_sched?: SettingsPreferenceEnum;
    buffer_minutes?: number;
    start_day_of_week?: Weekdays;
    send_officers_sched_sms?: boolean;
    clock_in_window_minutes?: number;
    firm_payroll_external_id?: string;
    accounts_receivable_email?: string;
    should_cc_ar_email_on_invoices?: boolean;
    tk_pay_period_kind?: TKSettingsPayPeriodKindEnum;
    tk_auto_approve_enabled?: boolean;
    tk_auto_approval_thresh_minutes?: number;
    tk_biweek_start_odd?: number;
    tk_pay_date_number_of_biz_days_after_period_end?: number;
    tk_auto_clock_out_minutes?: number;
    should_round_tk_to_15_minutes?: boolean;
    round_direction_punch_in?: Settings.round_direction_punch_in;
    round_direction_punch_out?: Settings.round_direction_punch_out;
    sm_enable_queue?: boolean;
    sm_offer_reminders?: boolean;
    sm_default_offer_reminder_wait_time_in_minutes?: number;
    sm_auto_offer_open_shifts?: boolean;
    sm_auto_offer_x_days_before_shift_start?: number;
    sm_default_offer_recipient_preferences?: Array<'AVAILABLE' | 'CERTIFIED' | 'NO_OVERTIME' | 'SITE_EXPERIENCE' | 'LOWER_OR_EQUAL_PAY_RATE' | 'BELOW_HOURLY_THRESHOLD' | 'WITHIN_RADIUS'>;
    sm_recipient_radius?: number;
    sm_hourly_threshold?: number;
    should_show_post_imp_payroll_ui?: boolean;
    timekeeping_changeover_date?: (string | null);
    use_24_holidays_only?: (boolean | null);
    should_hide_paper_checks_officer_payroll_option?: boolean;
    enable_relief_officers?: boolean;
    enable_gross_pay_view_for_officers?: boolean;
    firm_payroll_signature_url?: string;
    regular_hours_product_description?: string;
    ot_hours_product_description?: string;
    holiday_hours_product_description?: string;
    display_24_hour_clock?: boolean;
    discrete_subactivity_report_rows?: boolean;
    onboarding_workers_are_non_schedulable?: boolean;
    /**
     * Flag to denote if this firm uses the blended pay rate or not in timekeeping calculations.
     */
    no_blended_pay_rates?: boolean;
    first_payroll_period_start_date?: (string | null);
    /**
     * Flag to denote if this firm should group shifts by default in scheduling view.
     */
    group_shifts_by_default?: boolean;
    belltower_report_cc_emails?: string;
    /**
     * Flag to denote if this firm should automatically log out officers on clock out.
     */
    officer_logout_on_clockout?: boolean;
};

export namespace Settings {

    export enum round_direction_punch_in {
        NEAREST = 'NEAREST',
        UP = 'UP',
        DOWN = 'DOWN',
    }

    export enum round_direction_punch_out {
        NEAREST = 'NEAREST',
        UP = 'UP',
        DOWN = 'DOWN',
    }


}

