import useSWR from 'swr'
import {
    NonCustomerWorkplace,
    NonCustomerWorkplaceCreate,
    PayrollService,
} from '../generated'

type LoadNCWType = () => NonCustomerWorkplace[] | undefined

export const useLoadNonCustomerWorkplace: LoadNCWType = () => {
    const { data, error } = useSWR(`/ncws/`, () =>
        PayrollService.getNonCustomerWorkplacesApiV1PayrollNonCustomerWorkplacesGet()
    )
    return data
}

type createNCWType = (
    workplace: NonCustomerWorkplaceCreate,
    mutate: any
) => Promise<any>
export const createNonCustomerWorkplace: createNCWType = (
    workplace,
    mutate
) => {
    return mutate(`/ncws/`, async () => {
        return await PayrollService.createNonCustomerWorkplaceApiV1PayrollNonCustomerWorkplacesPost(
            workplace
        )
    })
}
