import React from 'react'
import { Officer } from '../../generated'
import {
    Button,
    Grid,
    Typography,
    ButtonGroup,
    Box,
    Alert,
} from '@mui/material'
import { restoreOfficer } from '../../services/officer'
import { Loading } from '../Loading'
import { useSWRConfig } from 'swr'
import { ApiError } from '../../generated'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import { ContentModal } from '../Modal'
import { PrimaryWorkplacePicker } from '../Officer/Payroll/PrimaryWorkplacePicker'

type RestoreModalProps = {
    open: boolean
    handleClose: () => void
    officer: Officer
    officerId: string
}

export const RestoreModal = ({
    open,
    handleClose,
    officer,
    officerId,
}: RestoreModalProps) => {
    const hasPrimaryWorkplace = officer.has_primary_workplace
    const { mutate } = useSWRConfig()
    const router = useRouter()
    const [submitting, setIsSubmitting] = React.useState<boolean>(false)
    const [primaryCustomerWorkplace, setPrimaryCustomerWorkplace] =
        React.useState<string | undefined>(undefined)
    const [primaryNonCustomerWorkplace, setPrimaryNonCustomerWorkplace] =
        React.useState<string | undefined>(undefined)

    const restoreOfficerCallback = React.useCallback(() => {
        setIsSubmitting(true)
        return restoreOfficer(
            officerId,
            primaryCustomerWorkplace,
            primaryNonCustomerWorkplace,
            mutate
        )
            .then((value: any) => {
                setIsSubmitting(false)
                handleClose()
            })
            .catch((error: ApiError) => {
                toast.error(error?.message)
                setIsSubmitting(false)
            })
    }, [primaryCustomerWorkplace, primaryNonCustomerWorkplace])

    const onPrimaryWorkplaceSaveClick = (update: Officer) => {
        setPrimaryCustomerWorkplace(
            update.payroll_primary_workplace_customer_id ?? undefined
        )
        setPrimaryNonCustomerWorkplace(
            update.payroll_primary_workplace_non_customer_id ?? undefined
        )
    }

    return (
        <ContentModal isOpen={open} handleClose={handleClose}>
            {submitting && <Loading style={{ minHeight: undefined }} />}
            {!submitting && (
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Typography variant="h2">Restore Worker</Typography>
                        <Typography variant="body1">
                            This will restore {officer.name} to no longer be
                            terminated.
                        </Typography>
                    </Grid>
                    {!hasPrimaryWorkplace && (
                        <Box p={1} px={3}>
                            {!primaryCustomerWorkplace &&
                                !primaryNonCustomerWorkplace && (
                                    <Alert severity="error">
                                        Sorry, you cannot restore {officer.name}{' '}
                                        without first choosing a primary
                                        workplace.
                                    </Alert>
                                )}
                            <PrimaryWorkplacePicker
                                officer={officer}
                                saveOfficer={onPrimaryWorkplaceSaveClick}
                            />
                        </Box>
                    )}

                    <Grid item marginTop={3}>
                        <ButtonGroup fullWidth>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button
                                variant="contained"
                                color="success"
                                disabled={
                                    !hasPrimaryWorkplace &&
                                    !primaryCustomerWorkplace &&
                                    !primaryNonCustomerWorkplace
                                }
                                onClick={() => restoreOfficerCallback()}
                            >
                                Restore worker
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            )}
        </ContentModal>
    )
}

type OfficerRestoreButtonProps = {
    officer: Officer | undefined
    id: string
    isTerminationAndPayrollPrivileged: boolean
}
export function OfficerRestoreButton({
    officer,
    id,
    isTerminationAndPayrollPrivileged,
}: OfficerRestoreButtonProps) {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    return (
        <>
            {officer && (
                <RestoreModal
                    open={open}
                    handleClose={handleClose}
                    officer={officer}
                    officerId={id}
                />
            )}
            <Button
                variant="text"
                color="primary"
                onClick={handleOpen}
                disabled={!isTerminationAndPayrollPrivileged || !officer}
                fullWidth
            >
                Restore Worker
            </Button>
        </>
    )
}
