import Color from 'tinycolor2'

export function stringToColor(string: string) {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff
        color += `00${value.toString(16)}`.slice(-2)
    }
    /* eslint-enable no-bitwise */

    return color
}

export function stringAvatar(
    name: string,
    size: number,
    border?: string,
    focused?: boolean
) {
    const color = Color(stringToColor(name || '?'))
    const complement = color.complement().toHexString()
    return {
        sx: {
            backgroundColor: color.toHexString(),
            width: size,
            height: size,
            border: { border },
            fontSize: size * 0.5,
        },
        children: toInitials(name),
        ...(focused ? { style: { borderColor: complement } } : {}),
    }
}

export function stringAvatarGreyBg(
    name: string,
    size: number,
    border?: string
) {
    return {
        sx: {
            backgroundColor: '#D9D9D9',
            width: size,
            height: size,
            border: { border },
            fontSize: size * 0.4,
            color: 'black',
        },
        children: toInitials(name),
    }
}

function toInitials(name?: string) {
    if (!name || name === undefined || name.length === 0) {
        return '?'
    }
    try {
        const nameParts = name.split(' ').map((a) => a.trim())
        const first =
            nameParts.length > 0 && nameParts[0].length > 0
                ? nameParts[0][0]
                : undefined
        const last =
            nameParts.length > 0 && nameParts[nameParts.length - 1].length > 0
                ? nameParts[nameParts.length - 1][0]
                : undefined

        if (first && last) {
            return `${first}${last}`
        } else {
            return name[0]
        }
    } catch (error) {
        return name[0]
    }
}
