/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuditRecord } from '../models/AuditRecord';
import type { AuditRecordDisplaySettings } from '../models/AuditRecordDisplaySettings';
import type { AuditRecordFilter } from '../models/AuditRecordFilter';
import type { Page_AuditRecord_ } from '../models/Page_AuditRecord_';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuditRecordService {

    /**
     * Get Audit Record Display Settings
     * @returns AuditRecordDisplaySettings Successful Response
     * @throws ApiError
     */
    public static getAuditRecordDisplaySettingsApiV1AuditRecordDisplaySettingsGet(): CancelablePromise<AuditRecordDisplaySettings> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/audit_record_display_settings',
        });
    }

    /**
     * Read Audit Records For Firm
     * Return audit records for a given firm, satisfying specified filter criteria.
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_AuditRecord_ Successful Response
     * @throws ApiError
     */
    public static readAuditRecordsForFirmApiV1AuditrecordPost(
        page: number = 1,
        size: number = 50,
        requestBody?: (AuditRecordFilter | null),
    ): CancelablePromise<Page_AuditRecord_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auditrecord',
            query: {
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Audit Record By Id
     * Return audit records by the supplied id.
     * @param auditRecordId
     * @returns AuditRecord Successful Response
     * @throws ApiError
     */
    public static getAuditRecordByIdApiV1AuditrecordAuditRecordIdGet(
        auditRecordId: string,
    ): CancelablePromise<AuditRecord> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/auditrecord/{audit_record_id}',
            path: {
                'audit_record_id': auditRecordId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
