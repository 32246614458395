/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ContractorPayrollEarningCreate } from './ContractorPayrollEarningCreate';
import type { ContractorPayrollReimbursementCreate } from './ContractorPayrollReimbursementCreate';

export type ContractorPayrollCreateOrUpdate = {
    id?: string;
    officer_id: string;
    payment_method?: ContractorPayrollCreateOrUpdate.payment_method;
    create_earning?: ContractorPayrollEarningCreate;
    delete_earning?: string;
    create_reimbursement?: ContractorPayrollReimbursementCreate;
    delete_reimbursement?: string;
};

export namespace ContractorPayrollCreateOrUpdate {

    export enum payment_method {
        DIRECT_DEPOSIT = 'direct_deposit',
        MANUAL = 'manual',
    }


}

