import React, { createContext, useState } from 'react'
import { useIsMobileScreenSize } from 'src/hooks/useIsMobileScreenSize'

type ShowMobileViewContextType = {
    shouldShowMobileView: boolean
    setShouldShowMobileView: (b: boolean) => void
    isMobileScreenSize: boolean
}

export const ShowMobileContext = createContext<ShowMobileViewContextType>({
    shouldShowMobileView: false,
    setShouldShowMobileView: () => {},
    isMobileScreenSize: false,
})

export const useShowMobileViewContext = () =>
    React.useContext(ShowMobileContext)

type ShowMobileViewContextWrapperProps = React.PropsWithChildren<{}>

export const ShowMobileViewContextWrapper: React.FC<
    ShowMobileViewContextWrapperProps
> = ({ children }: ShowMobileViewContextWrapperProps) => {
    const isMobileScreenSize = useIsMobileScreenSize()
    const [shouldShowMobileView, setShouldShowMobileView] =
        useState(isMobileScreenSize)
    return (
        <ShowMobileContext.Provider
            value={{
                shouldShowMobileView,
                setShouldShowMobileView,
                isMobileScreenSize,
            }}
        >
            {children}
        </ShowMobileContext.Provider>
    )
}

export function WithShowDesktopOrMobileHOC<T, K>(
    DesktopComponent: React.ComponentType<T>,
    MobileComponent: React.ComponentType<K>
) {
    const CompHoc = (props: T & K) => {
        const { shouldShowMobileView } = React.useContext(ShowMobileContext)
        return !shouldShowMobileView ? (
            <DesktopComponent {...(props as JSX.IntrinsicAttributes & T)} />
        ) : (
            <MobileComponent {...(props as JSX.IntrinsicAttributes & K)} />
        )
    }
    return CompHoc
}
