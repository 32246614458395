import React, { useMemo } from 'react'
import { useRouter } from 'next/router'
import { useAuth0 } from '@auth0/auth0-react'
import MuiDrawer from '@mui/material/Drawer'
import MuiSwipeableDrawer from '@mui/material/SwipeableDrawer'
import { useTheme, Theme, CSSObject, ThemeProvider } from '@mui/material/styles'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { NotificationInboxWrapper } from '../../components/NotificationInboxWrapper'
import { useCurrentUserRegions } from '../../services/region'
import ComputerIcon from '@mui/icons-material/Computer'
import SmartphoneIcon from '@mui/icons-material/Smartphone'
import {
    Box,
    Divider,
    Toolbar,
    styled,
    Typography,
    Stack,
    Menu,
    MenuItem,
    List,
} from '@mui/material'
import { useMaxRoleOfUser, useRolesOfUser } from '../../utils/auth'
import { useLoadFirmWithAddons } from '../../services/firm'
import newBelfryTheme from 'src/shared/styles/theme/newBelfryTheme'
import { type NavMenuLink, getNavOptions } from '../SideNav/navOptions'
import { SideNavMenu } from '../SideNav'
import HelpIcon from '@mui/icons-material/Help'
import { ArrowDropDown, Person } from '@mui/icons-material'
import { useGetMineralUsername } from 'src/services/officer'
import { useMineralSso } from 'src/hooks/useMineralSso'
import { RegionSelect } from '../RegionSelect/RegionSelect'
import { ShowMobileContext } from '../MobileContextAndHoc/MobileContextAndHoc'
import { FEATURE_GATE } from 'src/constants/previewEligibleOrgs'
import { FirmTitle, FirmVisitationModal } from 'src/components/FirmSwitching'
import { FirmVisitationBanner } from '../FirmSwitching/FirmVisitationBanner'
import NextLink from 'next/link'

const drawerWidthOpen = 247
const drawerWidthClosed = 68
const appBarHeight = 64

const regionEditablePaths = [
    '/employees',
    '/customers',
    '/customers/sites',
    '/customers/parents',
    '/billing/[[...invoiceParams]]',
    '/scheduling/details',
    '/scheduling/marketplace',
    '/scheduling/customer',
    '/scheduling/officer',
    '/scheduling/time_off/upcoming',
    '/scheduling/time_off/pending',
    '/scheduling/time_off/past',
    '/timekeeping/day',
    '/timekeeping/period/default',
    '/settings/certifications',
    '/settings/regions',
    '/settings/states',
    '/settings/scheduling',
    '/settings/timekeeping',
    '/belltower/livedashboard',
    '/belltower/activities',
    '/belltower/reports',
    '/belltower/announcements',
]

const pathsWithMobileVersions = [
    '/belltower/livedashboard',
    '/belltower/activities',
    '/belltower/reports',
    '/belltower/announcements',
    '/scheduling/details',
]

const MainLayoutStylingWithBreakpoints = {
    marginRight: {
        sm: 1,
        xl: 3,
    },
    marginLeft: {
        sm: 1,
        lg: 2,
        xl: 3,
    },
    paddingTop: { xs: 1, sm: 0 },
    flexGrow: 1,
    bgcolor: 'background.default',
}

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidthOpen,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('md')]: {
        width: `${drawerWidthClosed}px`,
    },
})

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    backgroundColor: '#fff',
    color: '#191C1D',
    width: `calc(100% - ${open ? drawerWidthOpen : drawerWidthClosed}px)`,
    borderBottom: `2px solid ${theme.palette.divider}`,
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    height: appBarHeight,
    [theme.breakpoints.down('md')]: {
        width: `100%`,
        paddingLeft: 0,
    },
}))

const NormalDrawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidthOpen,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}))

type LayoutProps = React.PropsWithChildren<{}>

export const SideLayout = ({ children }: LayoutProps) => {
    const { logout } = useAuth0()
    const router = useRouter()
    const theme = useTheme()
    const rolesOfUser = useRolesOfUser()
    const { firm } = useLoadFirmWithAddons()

    const [open, setOpen] = React.useState(false)
    const [firmVisitModalOpen, setFirmVisitModalOpen] = React.useState(false)
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
        null
    )
    const { regions: currentRegions, isLoading } = useCurrentUserRegions()

    const {
        shouldShowMobileView,
        setShouldShowMobileView,
        isMobileScreenSize,
    } = React.useContext(ShowMobileContext)

    const maxRole = useMaxRoleOfUser()
    const isMineral =
        maxRole !== 'Supervisor' &&
        firm?.addons?.find((addon) => addon.addon_kind == 'CHECKHQ_PAYROLL')

    const { mineral_username } = useGetMineralUsername(!!isMineral)
    const mineralUrl = useMineralSso(isMineral ? mineral_username : undefined)

    const navOptions = useMemo<NavMenuLink[]>(() => {
        if (!rolesOfUser || !firm) {
            return []
        }
        return getNavOptions(
            rolesOfUser,
            firm,
            { mineral: mineralUrl },
            FEATURE_GATE
        )
    }, [firm, rolesOfUser, mineralUrl])

    // grabs the first part of the current pathname, e.g. "/timekeeping/day" -> "/timekeeping"
    const currentPathNameRoot = router.pathname.match(/^\/[a-z]+/g)?.[0] ?? ''
    const activeNavOption = navOptions.find((option) =>
        currentPathNameRoot.includes(option.path)
    )
    const title = activeNavOption?.appBarTitle || activeNavOption?.title

    const handleDrawerToggle = () => {
        setOpen(!open)
    }

    const logOutUser = () => {
        if (window && window.hasOwnProperty('zE')) {
            window.zE('messenger', 'logoutUser')
        }
        logout({
            returnTo: window.location.origin,
        })
    }

    const canEditRegions = regionEditablePaths.includes(router.pathname)
    const hasMobileVersion = pathsWithMobileVersions.includes(router.pathname)
    const showMobileVersusDesktopSwitch =
        (shouldShowMobileView || isMobileScreenSize) && hasMobileVersion

    const sharedDrawerProps = {
        PaperProps: {
            sx: {
                backgroundColor: theme.palette.nav.main,
                color: theme.palette.nav.contrastText,
            },
        },
        sx: { zIndex: theme.zIndex.appBar + 1, flexShrink: 0 },
    }

    const drawerInner = (
        <>
            <DrawerHeader>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                    edge="start"
                    sx={{ margin: '5px' }}
                >
                    <MenuIcon />
                </IconButton>
                {open && firm?.name && (
                    <FirmTitle
                        onFirmSwitchClick={() => setFirmVisitModalOpen(true)}
                        firm={firm}
                    />
                )}
            </DrawerHeader>
            <Divider />
            <SideNavMenu
                navOptions={navOptions}
                activePath={router.pathname}
                expanded={open}
            />
        </>
    )

    const drawer = shouldShowMobileView ? (
        <>
            <MuiSwipeableDrawer
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                {...sharedDrawerProps}
            >
                {drawerInner}
            </MuiSwipeableDrawer>
        </>
    ) : (
        <>
            <NormalDrawer
                variant="permanent"
                open={open}
                {...sharedDrawerProps}
            >
                {drawerInner}
            </NormalDrawer>
        </>
    )

    return (
        <Box sx={{ display: 'flex', height: '100%' }} pt={`${appBarHeight}px`}>
            <ThemeProvider theme={newBelfryTheme}>
                <AppBar position="fixed" open={open} elevation={0}>
                    <Toolbar
                        disableGutters
                        sx={{
                            [theme.breakpoints.up('md')]: {
                                paddingLeft: '20px',
                            },
                        }}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent={'flex-start'}
                            width="100%"
                        >
                            <Box
                                minHeight={64}
                                minWidth={64}
                                sx={{
                                    display: 'flex',
                                    backgroundColor: theme.palette.nav.main,
                                    [theme.breakpoints.up('md')]: {
                                        display: 'none',
                                    },
                                }}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <IconButton
                                    disableRipple
                                    sx={{
                                        color: theme.palette.nav.contrastText,
                                        borderRadius: 0,
                                    }}
                                    aria-label="open drawer"
                                    onClick={handleDrawerToggle}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Box>
                            <Typography
                                variant="h6"
                                component="div"
                                textOverflow="ellipsis"
                                overflow="hidden"
                                flexBasis={500}
                                flexShrink={2.5}
                                marginRight={'auto'}
                                marginLeft="10px"
                                sx={{ display: { xs: 'none', sm: 'initial' } }}
                            >
                                {title}
                            </Typography>
                            <Box sx={{ mr: 'auto' }}>
                                <FirmVisitationBanner />
                            </Box>

                            {!isLoading &&
                                currentRegions.length > 0 &&
                                canEditRegions && <RegionSelect />}

                            {showMobileVersusDesktopSwitch ? (
                                <IconButton
                                    onClick={() =>
                                        setShouldShowMobileView(
                                            !shouldShowMobileView
                                        )
                                    }
                                    sx={{ ml: 1 }}
                                    size="large"
                                    data-dd-action-name={
                                        shouldShowMobileView
                                            ? 'switch to desktop view'
                                            : 'switch to mobile view'
                                    }
                                >
                                    {shouldShowMobileView ? (
                                        <ComputerIcon color="primary" />
                                    ) : (
                                        <SmartphoneIcon color="primary" />
                                    )}
                                </IconButton>
                            ) : null}
                            <NotificationInboxWrapper />
                            <IconButton
                                onClick={() =>
                                    window.open(
                                        'https://belfryhelp.zendesk.com/hc/en-us',
                                        '_blank'
                                    )
                                }
                            >
                                <HelpIcon color="primary" />
                            </IconButton>
                            <IconButton
                                onClick={(e) =>
                                    setMenuAnchorEl(e.currentTarget)
                                }
                                sx={{
                                    borderRadius: 0,
                                }}
                            >
                                <Person
                                    sx={{
                                        color: 'black',
                                    }}
                                />
                                <ArrowDropDown
                                    sx={{
                                        ml: '15px',
                                        color: 'black',
                                    }}
                                />
                            </IconButton>
                            <Menu
                                id="app-bar-menu"
                                open={Boolean(menuAnchorEl)}
                                keepMounted
                                anchorEl={menuAnchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                onClose={() => setMenuAnchorEl(null)}
                                MenuListProps={{
                                    style: { padding: 0 },
                                }}
                            >
                                <List>
                                    <NextLink
                                        passHref
                                        href="/settings/user"
                                        legacyBehavior
                                    >
                                        <MenuItem>My Settings</MenuItem>
                                    </NextLink>
                                    <MenuItem onClick={() => logOutUser()}>
                                        Logout
                                    </MenuItem>
                                </List>
                            </Menu>
                        </Stack>
                    </Toolbar>
                </AppBar>
                {drawer}
            </ThemeProvider>
            <Box component="main" sx={MainLayoutStylingWithBreakpoints}>
                {children}
            </Box>
            {firmVisitModalOpen && (
                <FirmVisitationModal
                    handleClose={() => setFirmVisitModalOpen(false)}
                />
            )}
        </Box>
    )
}
