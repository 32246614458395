import React, { useState } from 'react'

import {
    Button,
    Typography,
    Autocomplete,
    TextField,
    Skeleton,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material'
import { Customer, NonCustomerWorkplace, Officer } from '../../../generated'
import { useLoadNonCustomerWorkplace } from '../../../services/non_customer_workplace'
import { useLoadCustomers } from '../../../services/customer'
import { toast } from 'react-toastify'

type PrimaryWorkplacePickerProps = {
    officer: Officer
    saveOfficer: (officer: Officer) => void
}

export function PrimaryWorkplacePicker({
    officer,
    saveOfficer,
}: PrimaryWorkplacePickerProps) {
    const { customers, isLoading } = useLoadCustomers()
    const nonCustomerWorkplaces = useLoadNonCustomerWorkplace()
    if (!customers || !nonCustomerWorkplaces || isLoading) {
        return <Skeleton />
    }
    return (
        <PrimaryWorkplacePickerInner
            saveOfficer={saveOfficer}
            officer={officer}
            customers={customers}
            nonCustomerWorkplaces={nonCustomerWorkplaces}
        />
    )
}

function PrimaryWorkplacePickerInner({
    officer,
    saveOfficer,
    customers,
    nonCustomerWorkplaces,
}: PrimaryWorkplacePickerProps & {
    customers: Customer[]
    nonCustomerWorkplaces: NonCustomerWorkplace[]
}) {
    const initialCustomer =
        customers.find(
            (x) => x.id === officer.payroll_primary_workplace_customer_id
        ) || null
    const initialNonCust =
        nonCustomerWorkplaces.find(
            (x) => x.id === officer.payroll_primary_workplace_non_customer_id
        ) || null

    const [selectedCustomer, setSelectedCustomer] = useState<null | Customer>(
        initialCustomer
    )
    const [selectedNonCust, setSelectedNonCust] =
        useState<null | NonCustomerWorkplace>(initialNonCust)

    // default `initialIsCustomerWorkplace` is false, most will be normal officers working at customer locations
    const initialIsCustomerWorkplace =
        officer.payroll_primary_workplace_non_customer_id === null
    const [isCustomerWorkplace, setIsCustomerWorkplace] = useState(
        initialIsCustomerWorkplace
    )

    const isValid = Boolean(
        (selectedCustomer && !selectedNonCust) ||
            (!selectedCustomer && selectedNonCust)
    )

    const onSaveClick = () => {
        if (!isValid) {
            return toast.error('Please select a valid workplace')
        }
        const payload =
            isCustomerWorkplace && selectedCustomer
                ? {
                      payroll_primary_workplace_customer_id:
                          selectedCustomer.id,
                      payroll_primary_workplace_non_customer_id: null,
                  }
                : {
                      payroll_primary_workplace_customer_id: null,
                      payroll_primary_workplace_non_customer_id:
                          selectedNonCust?.id,
                  }
        saveOfficer({ ...officer, ...payload })
    }

    return (
        <>
            <Typography variant="h6" mb={2}>
                Primary Workplace
            </Typography>
            <Typography>
                For payroll/tax purposes, please choose the primary workplace
                for {officer.name}.{' '}
            </Typography>

            <RadioGroup
                row
                onChange={(e) => {
                    setIsCustomerWorkplace(
                        e.target.value === 'true' ? true : false
                    )
                }}
                value={isCustomerWorkplace}
                sx={{ mb: 1 }}
            >
                <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Primary workplace is a customer"
                />
                <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Primary workplace is not a customer (backoffice, etc)"
                />
            </RadioGroup>
            {isCustomerWorkplace ? (
                <CustomerPickerInput
                    customers={customers}
                    setSelectedCustomer={(val: Customer) => {
                        setSelectedCustomer(val)
                        setSelectedNonCust(null)
                    }}
                    selectedCustomer={selectedCustomer}
                />
            ) : (
                <NonCustPickerInput
                    nonCustWorkplaces={nonCustomerWorkplaces}
                    setSelectedNonCust={(val: NonCustomerWorkplace) => {
                        setSelectedNonCust(val)
                        setSelectedCustomer(null)
                    }}
                    selectedNonCust={selectedNonCust}
                />
            )}
            <Button
                variant="contained"
                sx={{ mt: 1 }}
                fullWidth
                disabled={!isValid}
                onClick={onSaveClick}
            >
                Save Primary Workplace
            </Button>
        </>
    )
}

type CustomerPickerInputProps = {
    customers: Customer[]
    selectedCustomer: Customer | null
    setSelectedCustomer: any
}

const isCustomerOptionEqualToValue = (option: any, value: any) => {
    return option.id === value.id
}

function CustomerPickerInput({
    customers,
    selectedCustomer,
    setSelectedCustomer,
}: CustomerPickerInputProps) {
    return (
        <Autocomplete
            options={customers}
            getOptionLabel={(option) => option.name}
            value={selectedCustomer}
            onChange={(event: any, newValue: Customer | null) => {
                setSelectedCustomer(newValue)
            }}
            filterSelectedOptions
            isOptionEqualToValue={isCustomerOptionEqualToValue}
            renderInput={(params) => (
                <TextField {...params} label="Pick a Customer" />
            )}
        />
    )
}

type NonCustPickerInputProps = {
    nonCustWorkplaces: NonCustomerWorkplace[]
    selectedNonCust: NonCustomerWorkplace | null
    setSelectedNonCust: any
}

const isNonCustOptionEqual = (option: any, value: any) => {
    return option.id === value.id
}

function NonCustPickerInput({
    nonCustWorkplaces,
    selectedNonCust,
    setSelectedNonCust,
}: NonCustPickerInputProps) {
    return (
        <Autocomplete
            options={nonCustWorkplaces}
            getOptionLabel={(option) => option.name}
            value={selectedNonCust}
            onChange={(event: any, newValue: NonCustomerWorkplace | null) => {
                setSelectedNonCust(newValue)
            }}
            filterSelectedOptions
            isOptionEqualToValue={isNonCustOptionEqual}
            renderInput={(params) => (
                <TextField {...params} label="Pick a Non-Customer Workplace" />
            )}
        />
    )
}
