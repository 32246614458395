import { UserCredentials } from 'src/generated/models/UserCredentials'
import { FunctionStatusType } from './sharedTypes'
import useSWR from 'swr'
import { ChatService } from 'src/generated/services/ChatService'
import {
    BelfryAPISmartGroupCreate,
    BelfryAPISmartGroupNascentRecipients,
    BelfryAPISmartGroupUpdate,
    BelfryChannelCreateResponse,
    BelfryChatChannelCreate,
    BelfryChatChannelModeratorUpdate,
    BelfryChatChannelUpdate,
    app__schemas__chat__ChatPermission as ChatPermission,
    ChatPermissionCreateUpdate,
    ChatSmartGroup,
    OfficerForEmployeeColleague,
} from 'src/generated'
import { BelfryChannelUpdateResponse } from 'src/generated/models/BelfryChannelUpdateResponse'
import { ScopedMutator } from 'swr/_internal'

type LoadUserCredentialsType = {
    credentials: UserCredentials | undefined
} & FunctionStatusType

type LoadUserCredentialsFunc = () => LoadUserCredentialsType
export const useLoadUserChatCredentials: LoadUserCredentialsFunc = () => {
    const { data, error } = useSWR(
        `/chat/credentials`,
        ChatService.readUserChatCredentialsApiV1ChatUserCredentialsGet
    )
    return {
        credentials: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadUserUnreadMessageCountType = {
    unreadCount: number | undefined
} & FunctionStatusType

type LoadUserUnreadMessageCountFunc = () => LoadUserUnreadMessageCountType
export const useLoadUserUnreadMessageCount: LoadUserUnreadMessageCountFunc =
    () => {
        const { data, error } = useSWR(
            `/chat/unread_count`,
            ChatService.readUserUnreadMessageCountApiV1ChatUnreadCountGet
        )
        return {
            unreadCount: data,
            isLoading: !error && !data,
            isError: error,
        }
    }

type createGroupChatType = (
    channelCreate: BelfryChatChannelCreate
) => Promise<BelfryChannelCreateResponse>
export const createGroupChat: createGroupChatType = async (channelCreate) => {
    return await ChatService.createChannelApiV1ChatChannelPost(channelCreate)
}

type createSmartGroupChatType = (
    channelCreate: BelfryAPISmartGroupCreate
) => Promise<BelfryChannelCreateResponse>
export const createSmartGroupChat: createSmartGroupChatType = async (
    channelCreate
) => {
    return await ChatService.createSmartGroupApiV1ChatSmartGroupPost(
        channelCreate
    )
}

type updateChannelMembersType = (
    channelUpdate: BelfryChatChannelUpdate
) => Promise<BelfryChannelUpdateResponse>
export const updateGroupChatMembers: updateChannelMembersType = async (
    channelUpdate
) => {
    return await ChatService.updateChannelMembersApiV1ChatChannelMembersPut(
        channelUpdate
    )
}

type updateChannelModeratorsType = (
    channelUpdate: BelfryChatChannelModeratorUpdate
) => Promise<void>
export const updateGroupChatModerators: updateChannelModeratorsType = async (
    channelUpdate
) => {
    await ChatService.updateChannelModeratorsApiV1ChatChannelModeratorsPut(
        channelUpdate
    )
}

type muteChannelType = (channelId: string) => Promise<void>
export const muteChannel: muteChannelType = async (channelId) => {
    await ChatService.muteChannelApiV1ChatChannelChannelIdMutePut(channelId)
}

type unmuteChannelType = (channelId: string) => Promise<void>
export const unmuteChannel: unmuteChannelType = async (channelId) => {
    await ChatService.unmuteChannelApiV1ChatChannelChannelIdUnmutePut(channelId)
}

type deleteChannelType = (channelId: string) => Promise<void>
export const deleteChannel: deleteChannelType = async (channelId) => {
    await ChatService.deleteChannelApiV1ChatChannelDelete({
        channel_id: channelId,
    })
}

type archiveChannelType = (channelId: string) => Promise<void>
export const archiveChannel: archiveChannelType = async (channelId) => {
    await ChatService.archiveChannelApiV1ChatChannelExternalChannelIdArchivePost(
        channelId
    )
}

type LoadFirmChatPermissionsType = {
    permissions: ChatPermission[]
} & FunctionStatusType

type LoadFirmChatPermissionsFunc = () => LoadFirmChatPermissionsType
export const useLoadFirmChatPermissions: LoadFirmChatPermissionsFunc = () => {
    const { data, error } = useSWR(
        `/chat/firm_permission`,
        ChatService.getFirmChatPermissionsApiV1ChatFirmPermissionsGet
    )
    return {
        permissions: data ?? [],
        isLoading: !error && !data,
        isError: error,
    }
}

type updateFirmChatPermissionsType = (
    permissions: ChatPermissionCreateUpdate[],
    mutate: ScopedMutator
) => Promise<ChatPermission[] | undefined>
export const updateFirmChatPermissions: updateFirmChatPermissionsType = async (
    permissions,
    mutate
) => {
    const options = { optimisticData: permissions, rollbackOnError: true }
    return mutate(
        `/chat/firm_permission`,
        async () => {
            return (
                (await ChatService.setFirmChatPermissionsApiV1ChatFirmPermissionsPut(
                    permissions
                )) ?? []
            )
        },
        options
    )
}

type fetchNascentRecipientsForSmartGroupType = (
    body: BelfryAPISmartGroupNascentRecipients
) => Promise<Array<OfficerForEmployeeColleague>>
export const fetchNascentRecipientsForSmartGroup: fetchNascentRecipientsForSmartGroupType =
    async (body) => {
        return ChatService.readNascentRecipientsForSmartGroupApiV1ChatSmartGroupNascentRecipientsPost(
            body
        )
    }

export type SmartGroupUpdateFunc = (
    channelId: string,
    smartGroup: BelfryAPISmartGroupUpdate
) => Promise<string>

type LoadSmartGroupType = {
    group: ChatSmartGroup | undefined
    mutations: {
        updateSmartGroup: SmartGroupUpdateFunc
    }
} & FunctionStatusType

type LoadSmartGroupFuncType = (
    external_channel_id: string | undefined
) => LoadSmartGroupType
export const useSmartGroup: LoadSmartGroupFuncType = (external_channel_id) => {
    // Always call useSWR unconditionally
    const { data, error, mutate } = useSWR(
        external_channel_id ? `/chat/smart_group/${external_channel_id}` : null, // Pass `null` to avoid calling the API if external_channel_id is undefined
        () =>
            ChatService.getSmartGroupApiV1ChatSmartGroupExternalChannelIdGet(
                external_channel_id!
            )
    )

    return {
        group: data ?? undefined,
        isLoading: !error && !data,
        isError: error,
        mutations: {
            updateSmartGroup: async (
                channelId: string,
                smartGroup: BelfryAPISmartGroupUpdate
            ) => {
                const res =
                    await ChatService.updateSmartGroupApiV1ChatSmartGroupBelfryChannelIdPut(
                        channelId,
                        smartGroup
                    )
                mutate((data) => data, { revalidate: true })
                return res
            },
        },
    }
}
