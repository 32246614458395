/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ReceivablePaymentUpdate = {
    amount_cents?: number;
    batch_number?: (string | null);
    memo?: string;
    method?: ReceivablePaymentUpdate.method;
    occurred_at?: (string | null);
    paid_reference_number?: (string | null);
    posted_at?: (string | null);
    status?: string;
    customer_id?: (string | null);
};

export namespace ReceivablePaymentUpdate {

    export enum method {
        CREDIT_CARD = 'Credit Card',
        ACH = 'ACH',
    }


}

