/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomReportCreate } from '../models/CustomReportCreate';
import type { Dispatch } from '../models/Dispatch';
import type { DispatchCreateForAssignment } from '../models/DispatchCreateForAssignment';
import type { DispatchCreateForOffer } from '../models/DispatchCreateForOffer';
import type { DispatchCustomReport } from '../models/DispatchCustomReport';
import type { DispatchCustomReportTemplate } from '../models/DispatchCustomReportTemplate';
import type { DispatchStatus } from '../models/DispatchStatus';
import type { DispatchUpdate } from '../models/DispatchUpdate';
import type { FilterEntryForDispatchTable } from '../models/FilterEntryForDispatchTable';
import type { Page_Dispatch_ } from '../models/Page_Dispatch_';
import type { Site } from '../models/Site';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DispatchService {

    /**
     * Create Dispatch For Assignment
     * @param requestBody
     * @returns Dispatch Successful Response
     * @throws ApiError
     */
    public static createDispatchForAssignmentApiV1DispatchPost(
        requestBody: DispatchCreateForAssignment,
    ): CancelablePromise<Dispatch> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/dispatch',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Dispatches
     * @param statuses
     * @param sites
     * @param officers
     * @returns Dispatch Successful Response
     * @throws ApiError
     */
    public static getDispatchesApiV1DispatchGet(
        statuses?: (Array<DispatchStatus> | null),
        sites?: (Array<string> | null),
        officers?: (Array<string> | null),
    ): CancelablePromise<Array<Dispatch>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dispatch',
            query: {
                'statuses': statuses,
                'sites': sites,
                'officers': officers,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Dispatch For Offer
     * @param requestBody
     * @returns Dispatch Successful Response
     * @throws ApiError
     */
    public static createDispatchForOfferApiV1DispatchForOfferPost(
        requestBody: DispatchCreateForOffer,
    ): CancelablePromise<Dispatch> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/dispatch_for_offer',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Dispatches Paginated
     * @param statuses
     * @param sites
     * @param officers
     * @param page Page number
     * @param size Page size
     * @returns Page_Dispatch_ Successful Response
     * @throws ApiError
     */
    public static getDispatchesPaginatedApiV1DispatchPaginatedGet(
        statuses?: (Array<DispatchStatus> | null),
        sites?: (Array<string> | null),
        officers?: (Array<string> | null),
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_Dispatch_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dispatch/paginated',
            query: {
                'statuses': statuses,
                'sites': sites,
                'officers': officers,
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Active Dispatch
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getActiveDispatchApiV1DispatchActiveGet(): CancelablePromise<(Dispatch | null)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dispatch/active',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Offers
     * @returns Dispatch Successful Response
     * @throws ApiError
     */
    public static getOffersApiV1DispatchOffersGet(): CancelablePromise<Array<Dispatch>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dispatch/offers',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Dispatch Count By Status
     * @returns number Successful Response
     * @throws ApiError
     */
    public static getDispatchCountByStatusApiV1DispatchCountByStatusGet(): CancelablePromise<Record<string, number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dispatch/count_by_status',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Officers For Dispatches
     * @param statuses
     * @param sites
     * @returns FilterEntryForDispatchTable Successful Response
     * @throws ApiError
     */
    public static getOfficersForDispatchesApiV1DispatchOfficersForDispatchesGet(
        statuses?: (Array<DispatchStatus> | null),
        sites?: (Array<string> | null),
    ): CancelablePromise<Array<FilterEntryForDispatchTable>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dispatch/officers_for_dispatches',
            query: {
                'statuses': statuses,
                'sites': sites,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Sites For Dispatches
     * @param statuses
     * @param officers
     * @returns FilterEntryForDispatchTable Successful Response
     * @throws ApiError
     */
    public static getSitesForDispatchesApiV1DispatchSitesForDispatchesGet(
        statuses?: (Array<DispatchStatus> | null),
        officers?: (Array<string> | null),
    ): CancelablePromise<Array<FilterEntryForDispatchTable>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dispatch/sites_for_dispatches',
            query: {
                'statuses': statuses,
                'officers': officers,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Suggested Title For Site
     * @param siteId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getSuggestedTitleForSiteApiV1DispatchSuggestedTitleForSiteGet(
        siteId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dispatch/suggested_title_for_site',
            query: {
                'site_id': siteId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Dispatch
     * @param dispatchId
     * @returns Dispatch Successful Response
     * @throws ApiError
     */
    public static getDispatchApiV1DispatchDispatchIdGet(
        dispatchId: string,
    ): CancelablePromise<Dispatch> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dispatch/{dispatch_id}',
            path: {
                'dispatch_id': dispatchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Patch Dispatch
     * @param dispatchId
     * @param requestBody
     * @returns Dispatch Successful Response
     * @throws ApiError
     */
    public static patchDispatchApiV1DispatchDispatchIdPatch(
        dispatchId: string,
        requestBody: DispatchUpdate,
    ): CancelablePromise<Dispatch> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/dispatch/{dispatch_id}',
            path: {
                'dispatch_id': dispatchId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Report Templates For Dispatch
     * @param dispatchId
     * @returns DispatchCustomReportTemplate Successful Response
     * @throws ApiError
     */
    public static getReportTemplatesForDispatchApiV1DispatchDispatchIdReportTemplatesGet(
        dispatchId: string,
    ): CancelablePromise<Array<DispatchCustomReportTemplate>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dispatch/{dispatch_id}/report_templates',
            path: {
                'dispatch_id': dispatchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Report For Dispatch
     * @param dispatchId
     * @param reportTemplateId
     * @param requestBody
     * @returns DispatchCustomReport Successful Response
     * @throws ApiError
     */
    public static createReportForDispatchApiV1DispatchDispatchIdReportReportTemplateIdPost(
        dispatchId: string,
        reportTemplateId: string,
        requestBody: CustomReportCreate,
    ): CancelablePromise<DispatchCustomReport> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/dispatch/{dispatch_id}/report/{report_template_id}',
            path: {
                'dispatch_id': dispatchId,
                'report_template_id': reportTemplateId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Site For Dispatch
     * @param dispatchId
     * @returns Site Successful Response
     * @throws ApiError
     */
    public static getSiteForDispatchApiV1DispatchDispatchIdSiteGet(
        dispatchId: string,
    ): CancelablePromise<Site> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dispatch/{dispatch_id}/site',
            path: {
                'dispatch_id': dispatchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Offered Officers For Dispatch
     * @param dispatchId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getOfferedOfficersForDispatchApiV1DispatchDispatchIdOfferedOfficersGet(
        dispatchId: string,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dispatch/{dispatch_id}/offered_officers',
            path: {
                'dispatch_id': dispatchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Decline Offer For Dispatch
     * @param dispatchId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static declineOfferForDispatchApiV1DispatchDispatchIdDeclineOfferPost(
        dispatchId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/dispatch/{dispatch_id}/decline_offer',
            path: {
                'dispatch_id': dispatchId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
