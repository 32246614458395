/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivityReportFiled } from '../models/ActivityReportFiled';
import type { ActivityReportFiledCreate } from '../models/ActivityReportFiledCreate';
import type { ActivityUnion } from '../models/ActivityUnion';
import type { ActivityUnionUpdate } from '../models/ActivityUnionUpdate';
import type { Body_file_activity_report_for_shift_instance_api_v2_activities__shift_id___date___index__post } from '../models/Body_file_activity_report_for_shift_instance_api_v2_activities__shift_id___date___index__post';
import type { CursorPage_HighLevelActivity_ } from '../models/CursorPage_HighLevelActivity_';
import type { Page_HighLevelActivity_ } from '../models/Page_HighLevelActivity_';
import type { Page_Union_ActivityReportFiled__BreakIn__BreakOut__CheckIn__CheckOut__CheckpointScanned__ClockIn__ClockOut__CustomReportFiled__IncidentReportFiled__PostOrderAcknowledged__TourCompleted__TourStarted__ } from '../models/Page_Union_ActivityReportFiled__BreakIn__BreakOut__CheckIn__CheckOut__CheckpointScanned__ClockIn__ClockOut__CustomReportFiled__IncidentReportFiled__PostOrderAcknowledged__TourCompleted__TourStarted__';
import type { PaginatedActivityFilter } from '../models/PaginatedActivityFilter';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ActivityService {

    /**
     * Read Activities For Firm
     * Return the activities for a users firm (all activities across all customers).
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_HighLevelActivity_ Successful Response
     * @throws ApiError
     */
    public static readActivitiesForFirmApiV1ActivitiesPost(
        page: number = 1,
        size: number = 50,
        requestBody?: (PaginatedActivityFilter | null),
    ): CancelablePromise<Page_HighLevelActivity_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/activities',
            query: {
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Activity By Id
     * Return the activity given an id
     * @param id
     * @returns ActivityUnion Successful Response
     * @throws ApiError
     */
    public static readActivityByIdApiV1ActivitiesIdGet(
        id: string,
    ): CancelablePromise<ActivityUnion> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/activities/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Activities For Customer
     * Return the activities for a customer.
     * @param id
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_HighLevelActivity_ Successful Response
     * @throws ApiError
     */
    public static readActivitiesForCustomerApiV1CustomersIdActivitiesPost(
        id: string,
        page: number = 1,
        size: number = 50,
        requestBody?: (PaginatedActivityFilter | null),
    ): CancelablePromise<Page_HighLevelActivity_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/customers/{id}/activities',
            path: {
                'id': id,
            },
            query: {
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Activities For Consolidated Activity Report
     * @param carId
     * @param id
     * @param page Page number
     * @param size Page size
     * @returns Page_Union_ActivityReportFiled__BreakIn__BreakOut__CheckIn__CheckOut__CheckpointScanned__ClockIn__ClockOut__CustomReportFiled__IncidentReportFiled__PostOrderAcknowledged__TourCompleted__TourStarted__ Successful Response
     * @throws ApiError
     */
    public static readActivitiesForConsolidatedActivityReportApiV1CustomerIdConsolidatedActivityReportCarIdActivitiesGet(
        carId: string,
        id: string,
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_Union_ActivityReportFiled__BreakIn__BreakOut__CheckIn__CheckOut__CheckpointScanned__ClockIn__ClockOut__CustomReportFiled__IncidentReportFiled__PostOrderAcknowledged__TourCompleted__TourStarted__> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/customer/{id}/consolidated-activity-report/{car_id}/activities/',
            path: {
                'car_id': carId,
                'id': id,
            },
            query: {
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * File Activity Report For Shift Instance
     * @param shiftId
     * @param date
     * @param index
     * @param requestBody
     * @param customerId
     * @param siteVisitOperationId
     * @returns ActivityReportFiled Successful Response
     * @throws ApiError
     */
    public static fileActivityReportForShiftInstanceApiV1ActivitiesShiftIdDateIndexPost(
        shiftId: string,
        date: string,
        index: number,
        requestBody: ActivityReportFiledCreate,
        customerId?: (string | null),
        siteVisitOperationId?: (string | null),
    ): CancelablePromise<ActivityReportFiled> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/activities/{shift_id}/{date}/{index}',
            path: {
                'shift_id': shiftId,
                'date': date,
                'index': index,
            },
            query: {
                'customer_id': customerId,
                'site_visit_operation_id': siteVisitOperationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * File Activity Report For Customer
     * @param customerId
     * @param requestBody
     * @param shiftInstanceId
     * @param siteVisitOperationId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static fileActivityReportForCustomerApiV1ActivitiesCustomerCustomerIdPost(
        customerId: string,
        requestBody: ActivityReportFiledCreate,
        shiftInstanceId?: (string | null),
        siteVisitOperationId?: (string | null),
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/activities/customer/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            query: {
                'shift_instance_id': shiftInstanceId,
                'site_visit_operation_id': siteVisitOperationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Customer Activity Export
     * Exports the activities for a customer as a csv.
     * @param customerId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createCustomerActivityExportApiV1CustomersCustomerIdActivitiesExportPost(
        customerId: string,
        requestBody?: (PaginatedActivityFilter | null),
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/customers/{customer_id}/activities/export',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Back Office Edit Activity
     * Allows back office users to edit activities
     * @param activityId
     * @param requestBody
     * @returns ActivityUnion Successful Response
     * @throws ApiError
     */
    public static backOfficeEditActivityApiV1ActivitiesActivityIdPut(
        activityId: string,
        requestBody: ActivityUnionUpdate,
    ): CancelablePromise<ActivityUnion> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/activities/{activity_id}',
            path: {
                'activity_id': activityId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * File Activity Report For Shift Instance
     * @param shiftId
     * @param date
     * @param index
     * @param requestBody
     * @param customerId
     * @param siteVisitOperationId
     * @returns ActivityReportFiled Successful Response
     * @throws ApiError
     */
    public static fileActivityReportForShiftInstanceApiV2ActivitiesShiftIdDateIndexPost(
        shiftId: string,
        date: string,
        index: number,
        requestBody: Body_file_activity_report_for_shift_instance_api_v2_activities__shift_id___date___index__post,
        customerId?: (string | null),
        siteVisitOperationId?: (string | null),
    ): CancelablePromise<ActivityReportFiled> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/activities/{shift_id}/{date}/{index}',
            path: {
                'shift_id': shiftId,
                'date': date,
                'index': index,
            },
            query: {
                'customer_id': customerId,
                'site_visit_operation_id': siteVisitOperationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Activities For Firm
     * Return the activities for a users firm (all activities across all customers).
     * @param cursor Cursor for the next page
     * @param size Page size
     * @param requestBody
     * @returns CursorPage_HighLevelActivity_ Successful Response
     * @throws ApiError
     */
    public static readActivitiesForFirmApiV2ActivitiesPost(
        cursor?: (string | null),
        size: number = 50,
        requestBody?: (PaginatedActivityFilter | null),
    ): CancelablePromise<CursorPage_HighLevelActivity_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/activities',
            query: {
                'cursor': cursor,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
