/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FirmAddon } from './FirmAddon';
import type { PostalAddress } from './PostalAddress';

export type FirmWithAddons = {
    name: string;
    org_id: string;
    checkhq_id?: string;
    checkhq_default_sig_id?: string;
    checkhq_processing_period?: string;
    payment_entrypoint?: string;
    payment_onboarding_status?: FirmWithAddons.payment_onboarding_status;
    hubspot_id?: string;
    id?: string;
    fein?: string;
    address?: PostalAddress;
    billing_address?: string;
    logo_url?: string;
    suspended?: boolean;
    addons?: Array<FirmAddon>;
    implementation_end_date?: string;
};

export namespace FirmWithAddons {

    export enum payment_onboarding_status {
        CREATED = 'Created',
        SUBMITTED = 'Submitted',
        HOLDING = 'Holding',
        UNDER_WRITING = 'UnderWriting',
        BOARDING = 'Boarding',
        DECLINED = 'Declined',
        ACTIVATED = 'Activated',
    }


}

