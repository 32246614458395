import React, { createContext, useState, useContext } from 'react'
import { BelfryChatPermission } from 'src/utils/chat'

const ChatContext = createContext<{
    permissions: BelfryChatPermission[]
    setPermissions: (permissions: BelfryChatPermission[]) => void
}>({
    permissions: [],
    setPermissions: () => {},
})

type ChatContextWrapperProps = React.PropsWithChildren<{}>

export const ChatContextWrapper = ({
    children,
    ...props
}: ChatContextWrapperProps) => {
    const [permissions, setPermissions] = useState<BelfryChatPermission[]>([])

    return (
        <ChatContext.Provider
            value={{
                permissions,
                setPermissions,
            }}
        >
            {children}
        </ChatContext.Provider>
    )
}

export const useBelfryChatContext = () => useContext(ChatContext)
