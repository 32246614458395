globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"f1d5b6dd23870964a366144609517c6588d3bae9"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
    dsn: SENTRY_DSN,

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    environment: process.env.NEXT_PUBLIC_VERCEL_ENV,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,
    replaysOnErrorSampleRate: 1.0,

    // This option is required for capturing headers and cookies.
    sendDefaultPii: true,

    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    ignoreErrors: [
        'Error: Login required',
        'AbortError: The user aborted a request.',
        'TypeError: NetworkError when attempting to fetch resource.',
        'Error: Cancel rendering route',
        'AbortError: Fetch is aborted',
        'TypeError: Load failed',
        'Abort fetching component for route',
        'IFrame has not responded within 5 seconds',
    ],

    integrations: [Sentry.httpClientIntegration()],
})
