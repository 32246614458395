/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContractorDeduction } from '../models/ContractorDeduction';
import type { ContractorDeductionCreate } from '../models/ContractorDeductionCreate';
import type { ContractorDeductionOverride } from '../models/ContractorDeductionOverride';
import type { ContractorDeductionOverrideCreate } from '../models/ContractorDeductionOverrideCreate';
import type { ContractorDeductionUpdate } from '../models/ContractorDeductionUpdate';
import type { ContractorDeductionWithOfficers } from '../models/ContractorDeductionWithOfficers';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContractorDeductionsService {

    /**
     * Read Contractor Deductions
     * Return a list of contractor deductions for the firm
     * @returns ContractorDeductionWithOfficers Successful Response
     * @throws ApiError
     */
    public static readContractorDeductionsApiV1ContractorsDeductionsGet(): CancelablePromise<Array<ContractorDeductionWithOfficers>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/contractors/deductions',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Contractor Deduction
     * Create a contractor deduction.
     * @param requestBody
     * @returns ContractorDeductionWithOfficers Successful Response
     * @throws ApiError
     */
    public static createContractorDeductionApiV1ContractorsDeductionsPost(
        requestBody: ContractorDeductionCreate,
    ): CancelablePromise<ContractorDeductionWithOfficers> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/contractors/deductions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Contractor Deductions For Officer
     * Return a list of contractor deductions for a particualr officer.
     * @param officerId
     * @returns ContractorDeductionWithOfficers Successful Response
     * @throws ApiError
     */
    public static readContractorDeductionsForOfficerApiV1ContractorsOfficerIdDeductionsGet(
        officerId: string,
    ): CancelablePromise<Array<ContractorDeductionWithOfficers>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/contractors/{officer_id}/deductions',
            path: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Contractor Deduction By Id
     * Return a contractor deduction with corresponding id.
     * @param id
     * @returns ContractorDeduction Successful Response
     * @throws ApiError
     */
    public static readContractorDeductionByIdApiV1ContractorsDeductionsIdGet(
        id: string,
    ): CancelablePromise<ContractorDeduction> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/contractors/deductions/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Contractor Deduction
     * Update a contractor deduction. Only attribute that is non updatable via this call is "is_archived"
     * @param id
     * @param requestBody
     * @returns ContractorDeductionWithOfficers Successful Response
     * @throws ApiError
     */
    public static updateContractorDeductionApiV1ContractorsDeductionsIdPut(
        id: string,
        requestBody: ContractorDeductionUpdate,
    ): CancelablePromise<ContractorDeductionWithOfficers> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/contractors/deductions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Archive Contractor Deduction
     * Archive contract deduction.
     * @param id
     * @returns ContractorDeductionWithOfficers Successful Response
     * @throws ApiError
     */
    public static archiveContractorDeductionApiV1ContractorsDeductionsArchivedIdPost(
        id: string,
    ): CancelablePromise<ContractorDeductionWithOfficers> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/contractors/deductions/archived/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Contractor Deduction Mapping
     * Assign contractor deduction to officer.
     * @param deductionId
     * @param requestBody
     * @returns ContractorDeductionWithOfficers Successful Response
     * @throws ApiError
     */
    public static createContractorDeductionMappingApiV1ContractorsDeductionsDeductionIdMappingsPost(
        deductionId: string,
        requestBody: Array<string>,
    ): CancelablePromise<Array<ContractorDeductionWithOfficers>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/contractors/deductions/{deduction_id}/mappings',
            path: {
                'deduction_id': deductionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Contractor Deduction Mapping
     * Remove officer to contractor deduction assignment
     * @param requestBody
     * @returns ContractorDeductionWithOfficers Successful Response
     * @throws ApiError
     */
    public static deleteContractorDeductionMappingApiV1ContractorsDeductionsMappingsDelete(
        requestBody: Array<string>,
    ): CancelablePromise<Array<ContractorDeductionWithOfficers>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/contractors/deductions/mappings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Contractor Deduction Mappings
     * Update a set of contractor deduction mappings
     * @param contractorId
     * @param requestBody
     * @returns ContractorDeductionWithOfficers Successful Response
     * @throws ApiError
     */
    public static updateContractorDeductionMappingsApiV1ContractorsContractorIdDeductionsMappingsPut(
        contractorId: string,
        requestBody: Array<string>,
    ): CancelablePromise<Array<ContractorDeductionWithOfficers>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/contractors/{contractor_id}/deductions/mappings',
            path: {
                'contractor_id': contractorId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Contractor Deduction Overrides
     * @param contractorId
     * @returns ContractorDeductionOverride Successful Response
     * @throws ApiError
     */
    public static getContractorDeductionOverridesApiV1ContractorsContractorIdDeductionsOverridesGet(
        contractorId: string,
    ): CancelablePromise<Array<ContractorDeductionOverride>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/contractors/{contractor_id}/deductions/overrides',
            path: {
                'contractor_id': contractorId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Contractor Deduction Override
     * @param contractorId
     * @param requestBody
     * @returns ContractorDeductionOverride Successful Response
     * @throws ApiError
     */
    public static createContractorDeductionOverrideApiV1ContractorsContractorIdDeductionsOverridesPost(
        contractorId: string,
        requestBody: ContractorDeductionOverrideCreate,
    ): CancelablePromise<Array<ContractorDeductionOverride>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/contractors/{contractor_id}/deductions/overrides',
            path: {
                'contractor_id': contractorId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Archive Contractor Deduction Override
     * @param overrideId
     * @returns ContractorDeductionOverride Successful Response
     * @throws ApiError
     */
    public static archiveContractorDeductionOverrideApiV1ContractorsDeductionsOverridesOverrideIdDelete(
        overrideId: string,
    ): CancelablePromise<Array<ContractorDeductionOverride>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/contractors/deductions/overrides/{override_id}',
            path: {
                'override_id': overrideId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
