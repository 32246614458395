import { createTheme } from '@mui/material/styles'
import type {} from '@mui/x-data-grid/themeAugmentation'

const white = '#FFFFFF'
const ourGrey500 = '#6B7280'
const indigo200 = '#C7D2FE'
const indigo600 = '#4F46E5'
const indigo700 = '#4338CA'
const lightIndigo = 'rgba(67, 56, 202, 0.08)'
const lightIndigoGhost = '#F5F8FF'
const lightGrayBlue = 'rgb(156, 163, 175, 0.32)'
const darkInactive = '#9CA3AF'

const navBlue = '#253A8E'
const navOffWhite = '#FEFEFE'

// radius
const xlargeRadius = 8
const largeRadius = 6

declare module '@mui/material/styles/createPalette' {
    interface Palette {
        primarySoft: PaletteColorOptions
        accent: PaletteColorOptions
        nav: Palette['primary']
        inactive: Palette['primary']
    }
    interface PaletteOptions {
        primarySoft: PaletteColorOptions
        accent: PaletteColorOptions
        nav: PaletteColorOptions
        inactive?: PaletteColorOptions
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        primarySoft: true
        accent: true
        active: true
        inactive: true
    }
    interface ButtonPropsSizeOverrides {
        xlarge: true
    }
    interface ButtonPropsVariantOverrides {
        selectable: true
    }
}

declare module '@mui/material/Radio' {
    interface RadioPropsColorOverrides {
        accent: true
    }
}

declare module '@mui/material/Divider' {
    interface DividerPropsVariantOverrides {
        fullWidthModal: true
    }
}

declare module '@mui/material/styles' {
    interface TypographyVariants {
        body1Bold: React.CSSProperties
        body2Bold: React.CSSProperties
        captionBold: React.CSSProperties
        dashboard: React.CSSProperties
        dashboardSecondary: React.CSSProperties
        dashboardBold: React.CSSProperties
        dashboardLink: React.CSSProperties
        pill: React.CSSProperties
        smallDetail: React.CSSProperties
    }

    interface TypographyVariantsOptions {
        body1Bold?: React.CSSProperties
        body2Bold?: React.CSSProperties
        captionBold?: React.CSSProperties
        dashboard?: React.CSSProperties
        dashboardSecondary?: React.CSSProperties
        dashboardBold?: React.CSSProperties
        dashboardLink?: React.CSSProperties
        pill?: React.CSSProperties
        smallDetail?: React.CSSProperties
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        body1Bold: true
        body2Bold: true
        captionBold: true
        dashboard: true
        dashboardSecondary: true
        dashboardBold: true
        dashboardLink: true
        cardMedium: true
        pill: true
        smallDetail: true
    }
}

const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: indigo700,
        },
        primarySoft: {
            main: indigo600,
        },
        secondary: {
            main: lightIndigoGhost,
        },
        accent: {
            main: indigo600,
        },
        text: {
            secondary: indigo700,
        },
        nav: {
            main: navBlue,
            contrastText: navOffWhite,
        },
        inactive: {
            main: darkInactive,
        },
        divider: 'rgba(224, 224, 224, 1)',
    },
    typography: { fontFamily: 'Inter' },
    components: {
        MuiTypography: {
            variants: [
                {
                    props: { variant: 'h1' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 800,
                        fontSize: '48px',
                    },
                },
                {
                    props: { variant: 'h2' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 800,
                        fontSize: '40px',
                    },
                },
                {
                    props: { variant: 'h3' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 800,
                        fontSize: '36px',
                    },
                },
                {
                    props: { variant: 'h4' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 800,
                        fontSize: '32px',
                    },
                },
                {
                    props: { variant: 'h5' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 800,
                        fontSize: '28px',
                    },
                },
                {
                    props: { variant: 'h6' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 800,
                        fontSize: '24px',
                    },
                },
                {
                    props: { variant: 'body1Bold' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: '16px',
                    },
                },
                {
                    props: { variant: 'body2Bold' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: '14px',
                    },
                },
                {
                    props: { variant: 'captionBold' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: '12px',
                    },
                },
                {
                    props: { variant: 'dashboard' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        fontSize: '12px',
                    },
                },
                {
                    props: { variant: 'dashboardSecondary' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        fontSize: '12px',
                        color: '#767676',
                    },
                },
                {
                    props: { variant: 'dashboardLink' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        fontSize: '12px',
                        color: navBlue,
                        cursor: 'pointer',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    },
                },
                {
                    props: { variant: 'dashboardBold' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        fontSize: '12px',
                    },
                },
                {
                    props: { variant: 'cardMedium' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        fontSize: '15px',
                        textTransform: 'uppercase',
                        color: '#767676',
                    },
                },
            ],
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'capitalize',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '0.75rem',
                    fontWeight: 700,
                    color: '#9CA3AF',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                // this was #9CA3AF in figma but that seems very light
                root: { color: '#8e8e8e', fontWeight: 700 },
                asterisk: { color: '#E92C2C' },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    fontFamily: 'Inter',
                    '&.Mui-disabled': {
                        color: ourGrey500,
                    },
                    '&.Mui-active': {
                        color: indigo700,
                    },
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    border: 'none',
                    '& .MuiDataGrid-footerContainer': {
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: darkInactive,
                        borderBottomRightRadius: xlargeRadius,
                        borderBottomLeftRadius: xlargeRadius,
                        borderTop: 'none',
                        alignContent: 'center',
                    },
                    '& .MuiDataGrid-main': {
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: darkInactive,
                        borderTopRightRadius: xlargeRadius,
                        borderTopLeftRadius: xlargeRadius,
                        borderBottom: 'none',
                        marginTop: '20px',
                    },
                    '& .MuiDataGrid-columnHeader': {
                        minHeight: '56px',
                        paddingLeft: '24px',
                        '& .MuiDataGrid-columnSeparator': {
                            visibility: 'hidden',
                        },
                    },
                    '& .MuiDataGrid-cell': {
                        paddingLeft: '24px',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                    paddingBottom: 0,
                    paddingTop: 0,
                    textTransform: 'none',
                },
            },
            variants: [
                {
                    props: { size: 'xlarge' },
                    style: {
                        fontSize: 16,
                        paddingLeft: 20,
                        paddingRight: 20,
                        borderRadius: xlargeRadius,
                        height: '50px' /* TODO: Height should be computed, mui does not respect the height based on font-size + line-height + padding currently */,
                    },
                },
                {
                    props: { size: 'large' },
                    style: {
                        fontSize: 16,
                        paddingLeft: 16,
                        paddingRight: 16,
                        borderRadius: largeRadius,
                        height: '42px' /* TODO: Height should be computed, mui does not respect the height based on font-size + line-height + padding currently */,
                    },
                },
                {
                    props: { size: 'medium' },
                    style: {
                        fontSize: 14,
                        paddingLeft: 16,
                        paddingRight: 16,
                        height: '38px' /* TODO: Height should be computed, mui does not respect the height based on font-size + line-height + padding currently */,
                        minHeight: '38px',
                    },
                },
                {
                    props: { size: 'small' },
                    style: {
                        fontSize: 14,
                        paddingLeft: 12,
                        paddingRight: 12,
                    },
                },
                {
                    props: { variant: 'contained', color: 'primarySoft' },
                    style: {
                        backgroundColor: indigo600,
                        color: white,
                        '&:hover': {
                            backgroundColor: indigo700,
                        },
                    },
                },
                {
                    props: { variant: 'contained', color: 'secondary' },
                    style: {
                        backgroundColor: lightIndigo,
                        color: indigo700,
                    },
                },
                {
                    props: { variant: 'outlined', color: 'secondary' },
                    style: {
                        backgroundColor: lightIndigoGhost,
                        color: indigo700,
                        borderColor: lightGrayBlue,
                        '&:hover': {
                            backgroundColor: indigo200,
                            borderColor: lightGrayBlue,
                        },
                    },
                },
                {
                    props: { variant: 'text', color: 'secondary' },
                    style: {
                        color: indigo600,
                        '&:hover': {
                            backgroundColor:
                                indigo200 /* can't currently find a figma element that corresponds to this */,
                            borderColor: lightGrayBlue,
                        },
                    },
                },
            ],
        },
        MuiDivider: {
            variants: [
                {
                    props: { variant: 'fullWidthModal' },
                    style: {
                        width: '1064px',
                        marginLeft: '-32px',
                        marginRight: '-32px',
                        position: 'relative',
                        overflow: 'hidden',
                    },
                },
            ],
        },
    },
})

export default lightTheme
