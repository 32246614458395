/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum InvoiceRateCause {
    REG = '',
    HOL = 'hol ',
    CONTRACT_OT_MAX = 'ot-contract-max ',
    EMPLOYEE_OT_MAX = 'ot-employee-max ',
    WAGE_OT = 'wage-ot ',
    WAGE_2_OT = 'wage-2-ot ',
    WORKWEEK_OT = 'workweek-ot',
}
