/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Schema object containing minimal set of attributes to present
 * useful information, about employee payroll, to the user.
 */
export type EmployeePayrollMinimal = {
    payroll_id: string;
    id: string;
    officer_id: string;
    officer_name?: string;
    payment_method?: EmployeePayrollMinimal.payment_method;
    amount_cents: number;
    reimbursement_amount_cents?: number;
};

export namespace EmployeePayrollMinimal {

    export enum payment_method {
        DIRECT_DEPOSIT = 'direct_deposit',
        MANUAL = 'manual',
    }


}

