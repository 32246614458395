/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EmployeePayrollEarningCreate } from './EmployeePayrollEarningCreate';
import type { EmployeePayrollReimbursementCreate } from './EmployeePayrollReimbursementCreate';

export type EmployeePayrollCreateOrUpdate = {
    id?: string;
    officer_id: string;
    payment_method?: EmployeePayrollCreateOrUpdate.payment_method;
    create_earning?: EmployeePayrollEarningCreate;
    delete_earning?: string;
    create_reimbursement?: EmployeePayrollReimbursementCreate;
    delete_reimbursement?: string;
};

export namespace EmployeePayrollCreateOrUpdate {

    export enum payment_method {
        DIRECT_DEPOSIT = 'direct_deposit',
        MANUAL = 'manual',
    }


}

