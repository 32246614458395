/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReceivablePayment } from '../models/ReceivablePayment';
import type { ReceivablePaymentCreate } from '../models/ReceivablePaymentCreate';
import type { ReceivablePaymentUpdate } from '../models/ReceivablePaymentUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccountsReceivablePaymentService {

    /**
     * Create Receivable Payment
     * Create a new receivable payment.
     * @param requestBody
     * @returns ReceivablePayment Successful Response
     * @throws ApiError
     */
    public static createReceivablePaymentApiV1AccountsreceivablePaymentPost(
        requestBody: ReceivablePaymentCreate,
    ): CancelablePromise<ReceivablePayment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/accountsreceivable/payment',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Receivable Payment
     * Get receivable payment by ID. Returns the most up-to-date version of the payment.
     * @param paymentId
     * @returns ReceivablePayment Successful Response
     * @throws ApiError
     */
    public static readReceivablePaymentApiV1AccountsreceivablePaymentPaymentIdGet(
        paymentId: string,
    ): CancelablePromise<ReceivablePayment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/accountsreceivable/payment/{payment_id}',
            path: {
                'payment_id': paymentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Receivable Payment
     * Update receivable payment by ID. May create a new version of the payment.
     * @param paymentId
     * @param requestBody
     * @returns ReceivablePayment Successful Response
     * @throws ApiError
     */
    public static updateReceivablePaymentApiV1AccountsreceivablePaymentPaymentIdPatch(
        paymentId: string,
        requestBody: ReceivablePaymentUpdate,
    ): CancelablePromise<ReceivablePayment> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/accountsreceivable/payment/{payment_id}',
            path: {
                'payment_id': paymentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Receivable Payment Versions
     * Get all versions of a receivable payment.
     * @param paymentId
     * @returns ReceivablePayment Successful Response
     * @throws ApiError
     */
    public static readReceivablePaymentVersionsApiV1AccountsreceivablePaymentPaymentIdVersionsGet(
        paymentId: string,
    ): CancelablePromise<Array<ReceivablePayment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/accountsreceivable/payment/{payment_id}/versions',
            path: {
                'payment_id': paymentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Receivable Payments
     * List all receivable payments.
     * @returns ReceivablePayment Successful Response
     * @throws ApiError
     */
    public static readReceivablePaymentsApiV1AccountsreceivablePaymentsGet(): CancelablePromise<Array<ReceivablePayment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/accountsreceivable/payments',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Receivable Payments With Invoice
     * List all receivable payments with their associated invoice data.
     * @returns ReceivablePayment Successful Response
     * @throws ApiError
     */
    public static readReceivablePaymentsWithInvoiceApiV1AccountsreceivablePaymentsFullGet(): CancelablePromise<Array<ReceivablePayment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/accountsreceivable/payments/full',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Receivable Payments By Invoice Id
     * List all current version receivable payments for an invoice.
     * @param invoiceId
     * @returns ReceivablePayment Successful Response
     * @throws ApiError
     */
    public static readReceivablePaymentsByInvoiceIdApiV1AccountsreceivablePaymentsInvoiceIdGet(
        invoiceId: string,
    ): CancelablePromise<Array<ReceivablePayment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/accountsreceivable/payments/{invoice_id}',
            path: {
                'invoice_id': invoiceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
