/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ReceivablePayment = {
    accounts_receivable_invoice_id?: (string | null);
    amount_cents: number;
    batch_number?: (string | null);
    memo?: string;
    method?: ReceivablePayment.method;
    occurred_at?: (string | null);
    paid_reference_number?: (string | null);
    posted_at?: (string | null);
    status?: string;
    customer_id?: (string | null);
    created_at: string;
    current_version_id?: string;
    id: string;
    is_archived: boolean;
    is_current: boolean;
    officer_updating_immutable_columns_id?: string;
    target_version_id?: string;
    invoice_number?: string;
    updated_by_name?: string;
};

export namespace ReceivablePayment {

    export enum method {
        CREDIT_CARD = 'Credit Card',
        ACH = 'ACH',
    }


}

