import React from 'react'
import { DetailTabs } from '../DetailTabs'
import { DetailsTab } from '../../types/detailsTab'
import { Features } from 'src/constants/previewEligibleOrgs'
import { usePreviewEligible } from 'src/hooks'

type TabbedBelltowerLayoutProps = React.PropsWithChildren<{
    manualTabIndex?: number
}>

export const TabbedBelltowerLayout: React.FC<TabbedBelltowerLayoutProps> = ({
    children,
    manualTabIndex,
}) => {
    const showDispatchTab = usePreviewEligible(Features.DISPATCH)

    let tabLinks: (DetailsTab | null)[] = [
        // See latest Figma of this tab layout here. https://www.figma.com/design/V7SLN84ulxPueRfO7Vhlv9/Reports?node-id=89-504&t=Yk1DoWP9ATW0tTuB-0
        {
            name: 'Live Dashboard',
            link: `/belltower/livedashboard`,
            linkRoot: `/belltower/livedashboard`,
        },
        {
            name: 'Activities',
            link: `/belltower/activities`,
            linkRoot: `/belltower/activities`,
        },
        {
            name: 'Reports',
            link: `/belltower/reports`,
            linkRoot: `/belltower/reports`,
        },
        {
            name: 'Report Templates',
            link: `/belltower/report-templates`,
            linkRoot: `/belltower/report-templates`,
            hideOnMobile: true,
        },
        {
            name: 'Announcements',
            link: `/belltower/announcements`,
            linkRoot: `/belltower/announcements`,
        },
    ]
    if (showDispatchTab) {
        tabLinks.push({
            name: 'Dispatch',
            link: `/belltower/dispatch`,
            linkRoot: `/belltower/dispatch`,
        })
    }
    const isFeatureFlagEnabledForCustomIncidentTypes = usePreviewEligible(
        Features.CUSTOM_INCIDENT_TYPES
    )

    // Filter out "Report Templates" if the feature flag is enabled, because it's lumped with the new Figma design for "Reports".
    if (isFeatureFlagEnabledForCustomIncidentTypes) {
        tabLinks = tabLinks.filter((tab) => tab?.name !== 'Report Templates')
    }

    return (
        <>
            <DetailTabs data={tabLinks} manualTabIndex={manualTabIndex} />
            {children}
        </>
    )
}
