/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CheckpointCreate } from './CheckpointCreate';
import type { ContactCreate } from './ContactCreate';
import type { ContractCreate } from './ContractCreate';
import type { CustomerStatus } from './CustomerStatus';
import type { PostalAddress } from './PostalAddress';
import type { PostOrderCreate } from './PostOrderCreate';
import type { ShiftCreate } from './ShiftCreate';
import type { SiteCreate } from './SiteCreate';
import type { TourCreate } from './TourCreate';

export type CustomerCreate = {
    name: string;
    address?: string;
    postal_address?: PostalAddress;
    billing_name?: string;
    billing_address?: PostalAddress;
    us_state?: string;
    phone_number?: string;
    region_id?: string;
    status: CustomerStatus;
    is_archived?: boolean;
    checkhq_id?: string;
    parent_id?: string;
    parent_billing_enabled?: boolean;
    type?: CustomerCreate.type;
    block_mobile_clock_in?: boolean;
    industry_id?: string;
    contract?: ContractCreate;
    contacts?: Array<ContactCreate>;
    shifts?: Array<ShiftCreate>;
    post_order?: PostOrderCreate;
    checkpoints?: Array<CheckpointCreate>;
    tours?: Array<TourCreate>;
    site?: SiteCreate;
    supervisor_ids?: Array<string>;
};

export namespace CustomerCreate {

    export enum type {
        SITE = 'SITE',
        PATROL = 'PATROL',
    }


}

