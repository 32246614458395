/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Payment = {
    amount_cents: number;
    memo?: string;
    occurred_at?: (string | null);
    posted_at?: (string | null);
    method?: Payment.method;
    status?: string;
    accounts_payable_invoice_id?: (string | null);
    batch_number?: (string | null);
    vendor_id?: (string | null);
    item_id?: (string | null);
    id: string;
    created_at: string;
    is_archived: boolean;
    officer_updating_immutable_columns_id?: string;
    is_current: boolean;
    current_version_id?: string;
    target_version_id?: string;
    invoice_number?: string;
    updated_by_name?: string;
};

export namespace Payment {

    export enum method {
        CREDIT_CARD = 'Credit Card',
        ACH = 'ACH',
    }


}

