/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Invoice } from './Invoice';
import type { PaymentClient } from './PaymentClient';
import type { PaymentRequestForTransaction } from './PaymentRequestForTransaction';

export type PaymentTransactionDetails = {
    status: PaymentTransactionDetails.status;
    status_updated_at: string;
    processor_transaction_id?: string;
    processor_transaction_status?: string;
    processor_settlement_status?: string;
    processor_status_updated_at?: string;
    processor_auth_code?: string;
    processor_notes?: string;
    batch_number?: string;
    deposit_date?: string;
    refunded_at?: string;
    refund_notes?: string;
    chargeback_at?: string;
    chargeback_notes?: string;
    ach_return_at?: string;
    ach_return_notes?: string;
    payment_request_id: string;
    firm_id: string;
    invoice_id: string;
    customer_id?: string;
    parent_customer_id?: string;
    payment_client_id: string;
    amount_cents: number;
    fee_amount_cents: number;
    fee_percentage?: string;
    outstanding_amount_cents: number;
    occurred_at: string;
    payment_type: PaymentTransactionDetails.payment_type;
    processing_order_id: string;
    idempotency_key: string;
    payment_number: number;
    id: string;
    payment_request: PaymentRequestForTransaction;
    payment_client: PaymentClient;
    invoice?: Invoice;
};

export namespace PaymentTransactionDetails {

    export enum status {
        INITIATED = 'Initiated',
        PROCESSING = 'Processing',
        CANCELED = 'Canceled',
        DECLINED = 'Declined',
        ERROR = 'Error',
        FAILED = 'Failed',
        ON_HOLD = 'On Hold',
        PAID = 'Paid',
        DEPOSITED = 'Deposited',
        SETTLED = 'Settled',
        FUNDED = 'Funded',
        REFUNDED = 'Refunded',
    }

    export enum payment_type {
        CREDIT_CARD = 'Credit Card',
        ACH = 'ACH',
    }


}

