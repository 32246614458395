import { useMemo } from 'react'
import { useLocalStorage } from 'src/hooks'

type VisitState = {
    visitId: string | null
    visitedFirmId: string | null
}
export const useFirmVisitationState = (onChange?: () => void) => {
    const { value: visitState, setValue: setVisitState } =
        useLocalStorage<VisitState>('belfry-visit', onChange)

    return useMemo(() => {
        const visitId = visitState?.visitId || null
        const visitedFirmId = visitState?.visitedFirmId || null
        return {
            visitId,
            visitedFirmId,
            visitActive: !!visitId,
            setVisitInfo: ({
                id,
                firmId,
            }: {
                id: string | null
                firmId: string | null
            }) => {
                setVisitState({
                    ...(visitState || { visitId: id, visitedFirmId: firmId }),
                    visitId: id,
                    visitedFirmId: firmId,
                })
                setTimeout(() => {
                    window.location.replace('/')
                })
            },
        }
    }, [visitState, setVisitState])
}
