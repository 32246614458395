import React from 'react'

import { Box, Stack, styled, Tab, Tabs, Typography } from '@mui/material'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { DetailsTab } from '../../types/detailsTab'

const TabText = styled(Typography)({
    fontWeight: 600,
    paddingTop: '12px',
})
const StyledTab = styled(Tab)({
    textTransform: 'capitalize',
    color: '#9CA3AF',
    opacity: 1,
    paddingTop: '4px',
    whiteSpace: 'nowrap',
    '&:hover': {
        color: 'black',
    },
})

function removeQueryString(url: string) {
    return url.split('?')[0]
}

const hideOnMobileSx = { display: { xs: 'none', sm: 'flex' } }

export const BelfryTabContainer: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => (
    <Stack
        borderBottom="1px solid #9CA3AF"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
        maxWidth="100vw"
        marginBottom={{ xs: 0, sm: 1 }}
    >
        {children}
    </Stack>
)

export const DetailTabs = ({
    data,
    variant = 'scrollable',
    extraTabEnd,
    manualTabIndex,
}: DetailTabProps) => {
    const router = useRouter()
    const activeTabIndex = data.findIndex((tab) =>
        tab ? removeQueryString(router.asPath).startsWith(tab.linkRoot) : false
    )
    // because we sometimes dynamically add tabs, this line prevents warnings from -1 not being found
    const activeTabResult = activeTabIndex === -1 ? 0 : activeTabIndex
    const activeTab =
        manualTabIndex !== undefined ? manualTabIndex : activeTabResult

    return (
        <BelfryTabContainer>
            <Tabs value={activeTab} variant={variant}>
                {data.map((tab, index) => {
                    if (!tab) return
                    const sx = index === activeTab ? { color: '#4338CA' } : {}
                    return (
                        <Link
                            href={tab.link}
                            passHref
                            key={tab.name}
                            legacyBehavior
                        >
                            <StyledTab
                                wrapped
                                label={
                                    <Box display="flex" flexDirection="row">
                                        <TabText sx={sx}>{tab.name}</TabText>
                                        {tab.extra ? (
                                            <Box
                                                paddingLeft={0.5}
                                                display="flex"
                                                alignItems="end"
                                            >
                                                {tab.extra}
                                            </Box>
                                        ) : null}
                                    </Box>
                                }
                                value={index}
                                sx={tab.hideOnMobile ? hideOnMobileSx : null}
                            />
                        </Link>
                    )
                })}
            </Tabs>
            {extraTabEnd && (
                <Box mt={1} display="flex">
                    {extraTabEnd}
                </Box>
            )}
        </BelfryTabContainer>
    )
}

type DetailTabProps = {
    data: (DetailsTab | null)[]
    variant?: 'fullWidth' | 'standard' | 'scrollable' | undefined
    extraTabEnd?: React.ReactNode
    manualTabIndex?: number
}
