import { toast } from 'react-toastify'
import useSWR from 'swr'
import { OnboardingService } from '../generated/services/OnboardingService'
import { OnboardingTemplate } from '../generated/models/OnboardingTemplate'
import { FunctionStatusType } from './sharedTypes'
import {
    EmployerOnboardingTasks,
    OnboardingAction,
    OnboardingActionUpdate,
    OnboardingAvailabilityAction,
    OnboardingAvailabilityActionUpdate,
    OnboardingDocumentCreate,
    OnboardingDocument,
    OnboardingI9ActionWithAnvilData,
    OnboardingWOTCAction,
    OnboardingDocumentUpdate,
    Officer,
    AnvilSigner,
} from '../generated'
import { errorReasonToString } from '../utils/errorUtils'
import { OnboardingTemplateUpdate } from '../generated/models/OnboardingTemplateUpdate'
import { OnboardingProfileAction } from '../generated/models/OnboardingProfileAction'
import { OnboardingProfileActionUpdate } from '../generated/models/OnboardingProfileActionUpdate'
import { useLoadOfficerWithOnboardingStatus } from './officer'
import { getTaskListElementProps } from '../../onboarding_portal/components/TaskList/TaskList'
import { OnboardingActionEEO1 } from '../generated/models/OnboardingActionEEO1'
import { OnboardingActionEEO1Update } from '../generated/models/OnboardingActionEEO1Update'
import { AnvilSignerUpdate } from 'src/generated/models/AnvilSignerUpdate'

type LoadOnboardingTemplateFuncType = {
    template: OnboardingTemplate | undefined
} & FunctionStatusType

type LoadOnboardingTemplateType = (id: string) => LoadOnboardingTemplateFuncType
export const useLoadOnboardingTemplate: LoadOnboardingTemplateType = (
    id: string
) => {
    const { data, error } = useSWR([`/onboarding_template/`, id], () =>
        OnboardingService.getTemplateApiV1OnboardingTemplateTemplateIdGet(id)
    )
    return {
        template: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadOnboardingTemplatesFuncType = {
    templates: OnboardingTemplate[] | undefined
} & FunctionStatusType

type LoadOnboardingTemplatesType = () => LoadOnboardingTemplatesFuncType
export const useLoadOnboardingTemplates: LoadOnboardingTemplatesType = () => {
    const { data, error } = useSWR(`/onboarding_templates/`, () =>
        OnboardingService.getTemplatesApiV1OnboardingTemplatesGet()
    )
    return {
        templates: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type updateOnboardingTemplateFuncType = (
    template: OnboardingTemplateUpdate,
    params: string[],
    mutate: any
) => Promise<any>
export const updateOnboardingTemplate: updateOnboardingTemplateFuncType =
    async (template, params, mutate) => {
        return mutate([`/onboarding_template/`, params], async () => {
            await OnboardingService.updateTemplateApiV1OnboardingTemplateTemplateIdPut(
                template.id,
                template
            )
                .then(() => {
                    toast.success('Saved successfully')
                })
                .catch((reason) => toast.error(errorReasonToString(reason)))
        })
    }

type deleteOnboardingTemplateFuncType = (
    template_id: string,
    mutate: any
) => Promise<any>
export const deleteOnboardingTemplate: deleteOnboardingTemplateFuncType =
    async (template_id, mutate) => {
        return mutate(`/onboarding_templates/`, async () => {
            await OnboardingService.deleteTemplateApiV1OnboardingTemplateTemplateIdDelete(
                template_id
            )
                .then(() => {
                    toast.success('Deleted successfully')
                })
                .catch((reason) => toast.error(errorReasonToString(reason)))
        })
    }

type LoadOnboardingDocumentsFuncType = {
    documents: OnboardingDocument[] | undefined
} & FunctionStatusType

type LoadOnboardingDocumentsType = () => LoadOnboardingDocumentsFuncType
export const useLoadOnboardingDocuments: LoadOnboardingDocumentsType = () => {
    const { data, error } = useSWR([`/onboarding_documents/`], () =>
        OnboardingService.getDocumentsApiV1OnboardingDocumentsGet()
    )
    return {
        documents: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type uploadDocumentFuncType = (
    document: OnboardingDocumentCreate
) => Promise<any>

export const uploadOnboardingDocument: uploadDocumentFuncType = async (
    document
) => {
    return await OnboardingService.uploadDocumentApiV1OnboardingDocumentPost(
        document
    )
}

type updateDocumentFuncType = (
    document_id: string,
    document: OnboardingDocumentUpdate
) => Promise<OnboardingDocument>

export const updateOnboardingDocument: updateDocumentFuncType = async (
    document_id,
    document
) => {
    return await OnboardingService.updateDocumentApiV1OnboardingDocumentDocumentIdPut(
        document_id,
        document
    )
}

type removeDocumentFromTemplateFuncType = (
    template_id: string,
    document_id: string,
    params: string[],
    mutate: any
) => Promise<any>

export const removeOnboardingDocumentFromTemplate: removeDocumentFromTemplateFuncType =
    async (template_id, document_id, params, mutate) => {
        return mutate([`/onboarding_documents/`, params], async () => {
            return await OnboardingService.removeDocumentFromTemplateApiV1OnboardingTemplateTemplateIdDocumentDocumentIdDelete(
                template_id,
                document_id
            )
        })
    }

type deleteDocumentFuncType = (
    id: string,
    params: string[],
    mutate: any
) => Promise<any>

export const deleteOnboardingDocument: deleteDocumentFuncType = async (
    id,
    params,
    mutate
) => {
    return mutate([`/onboarding_documents/`, params], async () => {
        return await OnboardingService.deleteDocumentApiV1OnboardingDocumentDocumentIdDelete(
            id
        )
    })
}

type LoadProfileOnboardingActionFuncType = {
    action: OnboardingProfileAction | undefined
} & FunctionStatusType
type LoadProfileOnboardingActionType = () => LoadProfileOnboardingActionFuncType
export const useLoadProfileOnboardingAction: LoadProfileOnboardingActionType =
    () => {
        const { data, error } = useSWR(`/onboarding/profile`, () =>
            OnboardingService.getCompleteProfileActionApiV1OnboardingProfileGet()
        )
        return {
            action: data,
            isLoading: !error && !data,
            isError: error,
        }
    }

type LoadWotcActionFuncType = {
    action: OnboardingWOTCAction | undefined
} & FunctionStatusType
type LoadWotcActionType = () => LoadWotcActionFuncType
export const useLoadWotcAction: LoadWotcActionType = () => {
    const { data, error } = useSWR(`/onboarding/wotc`, () =>
        OnboardingService.getWotcActionApiV1OnboardingWotcGet()
    )
    return {
        action: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type updateProfileOnboardingActionFuncType = (
    action: OnboardingProfileActionUpdate,
    params: string[],
    mutate: any
) => Promise<any>

export const updateProfileOnboardingAction: updateProfileOnboardingActionFuncType =
    async (action, params, mutate) => {
        return mutate([`/onboarding/profile`, params], async () => {
            return await OnboardingService.updateCompleteProfileActionApiV1OnboardingProfilePut(
                action
            )
        })
    }

type LoadI9OnboardingActionFuncType = {
    action: OnboardingI9ActionWithAnvilData | undefined
} & FunctionStatusType
type LoadI9OnboardingActionType = (
    id: string | undefined
) => LoadI9OnboardingActionFuncType
export const useLoadI9OnboardingAction: LoadI9OnboardingActionType = (
    id: string | undefined
) => {
    const { data, error } = useSWR(
        [`/onboarding/i9`, id],
        id
            ? () =>
                  OnboardingService.getI9ActionApiV1OnboardingI9ActionIdGet(id)
            : null
    )

    return {
        action: data,
        isLoading: id ? !error && !data : false,
        isError: id ? error : null,
    }
}

export const downloadI9File = async (actionId: string, fileId: string) => {
    const resp =
        OnboardingService.getI9FileApiV1OnboardingI9ActionIdFileFileIdGet(
            actionId,
            fileId
        )
    return resp
}

type LoadAvailabilityOnboardingActionFuncType = {
    action: OnboardingAvailabilityAction | undefined
} & FunctionStatusType
type LoadAvailabilityOnboardingActionType = (
    id: string
) => LoadAvailabilityOnboardingActionFuncType
export const useLoadAvailabilityOnboardingAction: LoadAvailabilityOnboardingActionType =
    (id: string) => {
        const { data, error } = useSWR(`/onboarding/availability`, () =>
            OnboardingService.getAvailabilityActionApiV1OnboardingAvailabilityActionIdGet(
                id
            )
        )
        return {
            action: data,
            isLoading: !error && !data,
            isError: error,
        }
    }

type updateAvailabilityOnboardingActionFuncType = (
    action_id: string,
    action: OnboardingAvailabilityActionUpdate,
    params: string[],
    mutate: any
) => Promise<any>

export const updateAvailabilityOnboardingAction: updateAvailabilityOnboardingActionFuncType =
    async (action_id, action, params, mutate) => {
        return mutate([`/onboarding/availability`, params], async () => {
            return await OnboardingService.updateAvailabilityActionApiV1OnboardingAvailabilityActionIdPut(
                action_id,
                action
            )
        })
    }

type LoadOnboardingActionFuncType = {
    action: OnboardingAction | undefined
} & FunctionStatusType
type LoadOnboardingActionType = (id: string) => LoadOnboardingActionFuncType
export const useLoadOnboardingAction: LoadOnboardingActionType = (
    id: string
) => {
    const { data, error } = useSWR([`/onboarding/action`, id], () =>
        OnboardingService.getActionApiV1OnboardingActionActionIdGet(id)
    )
    return {
        action: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadOnboardingDocumentFuncType = {
    document: OnboardingDocument | undefined
} & FunctionStatusType
type LoadOnboardingDocumentType = (id: string) => LoadOnboardingDocumentFuncType
export const useLoadOnboardingDocument: LoadOnboardingDocumentType = (
    id: string
) => {
    const { data, error } = useSWR([`/onboarding/document`, id], () =>
        OnboardingService.getDocumentApiV1OnboardingDocumentDocumentIdGet(id)
    )
    return {
        document: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type updateOnboardingActionFuncType = (
    actionId: string,
    action: OnboardingActionUpdate,
    mutate: any
) => Promise<any>

export const updateOnboardingAction: updateOnboardingActionFuncType = async (
    actionId,
    action,
    mutate
) => {
    return mutate([`/onboarding/action`, actionId], async () => {
        return await OnboardingService.updateActionApiV1OnboardingActionActionIdPut(
            actionId,
            action
        )
    })
}

type LoadEmployerOnboardingActionFuncType = {
    tasks: EmployerOnboardingTasks | undefined
} & FunctionStatusType
type LoadEmployerOnboardingActionType = (
    excludeCompleted: boolean
) => LoadEmployerOnboardingActionFuncType
export const useLoadEmployerOnboardingAction: LoadEmployerOnboardingActionType =
    (excludeCompleted: boolean) => {
        const { data, error } = useSWR(
            [`/onboarding/action`, excludeCompleted],
            () =>
                OnboardingService.getEmployerActionsApiV1OnboardingActionsEmployerGet(
                    excludeCompleted
                )
        )
        return {
            tasks: data,
            isLoading: !error && !data,
            isError: error,
        }
    }

type LoadNextActionURLFuncType = {
    url: string
} & FunctionStatusType
type LoadNextActionURLType = (
    currentActionID: string | undefined
) => LoadNextActionURLFuncType
export const useLoadNextActionURL: LoadNextActionURLType = (
    currentActionID
) => {
    const { officer, isLoading, isError } = useLoadOfficerWithOnboardingStatus()
    if (isLoading) {
        return {
            url: '/dashboard',
            isLoading: true,
            isError: false,
        }
    }
    const tasks = officer?.onboarding_status?.actions
    if (isError || tasks == undefined) {
        return {
            url: '/dashboard',
            isLoading: false,
            isError: false,
        }
    }

    const tasksNotComplete = tasks.filter(
        (action) =>
            (action.status == OnboardingAction.status.NOT_STARTED ||
                action.status == OnboardingAction.status.IN_PROGRESS) &&
            action.id != currentActionID
    )
    if (!tasksNotComplete.length) {
        return {
            url: '/dashboard',
            isLoading: false,
            isError: false,
        }
    }

    const nextTask = tasksNotComplete[0]
    const url = getTaskListElementProps(nextTask).href

    return {
        url: url,
        isLoading: false,
        isError: false,
    }
}

type LoadEEO1OnboardingActionFuncType = {
    action: OnboardingActionEEO1 | undefined
} & FunctionStatusType
type LoadEEO1OnboardingActionType = (
    id: string
) => LoadEEO1OnboardingActionFuncType
export const useLoadEEO1OnboardingAction: LoadEEO1OnboardingActionType = (
    id: string
) => {
    const { data, error } = useSWR([`/onboarding_action/eeo1`, id], () =>
        OnboardingService.getEeo1ActionApiV1OnoardingEeo1ActionIdGet(id)
    )
    return {
        action: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type updateEEO1OnboardingActionFuncType = (
    actionId: string,
    action: OnboardingActionEEO1Update,
    mutate: any
) => Promise<any>
export const updateEEO1OnboardingAction: updateEEO1OnboardingActionFuncType =
    async (actionId, action, mutate) => {
        return mutate([`/onboarding_action/eeo1`, actionId], async () => {
            return await OnboardingService.updateEeo1ActionApiV1OnboardingEeo1ActionIdPut(
                actionId,
                action
            )
        })
    }

export const sendEmployeePortalInviteEmail = async (officer: Officer) => {
    try {
        const prom =
            OnboardingService.sendOnboardingInviteApiV1OnboardingSendEmployeeInvitePost(
                officer.id
            )
        await toast.promise(prom, {
            pending: `Sending employee portal email to ${officer.email}`,
            success: 'Sent employee portal email',
            error: 'Error sending employee portal email',
        })
    } catch (error) {
        toast.error(`${error}`)
    }
}

type resetI9FuncType = (action_id: string) => Promise<string>

export const resetI9: resetI9FuncType = async (action_id) => {
    return await OnboardingService.resetI9ApiV1OnboardingI9ActionIdResetPost(
        action_id
    )
}

type updateI9SignerFuncType = (
    action_id: string,
    signer: AnvilSignerUpdate
) => Promise<AnvilSigner>

export const updateI9Signer: updateI9SignerFuncType = async (
    action_id,
    signer
) => {
    return await OnboardingService.editI9SignerApiV1OnboardingI9ActionIdSignerSignerEidPost(
        action_id,
        signer
    )
}

type notifyI9SignerFuncType = (
    action_id: string,
    signer_eid: string
) => Promise<AnvilSigner>

export const notifyI9Signer: notifyI9SignerFuncType = async (
    action_id,
    signer_eid
) => {
    return await OnboardingService.notifyI9SignerApiV1OnboardingI9ActionIdSignerSignerEidNotifyPost(
        action_id,
        signer_eid
    )
}
