import { Alert, Button, Stack, Tooltip } from '@mui/material'
import { type FC, useCallback } from 'react'
import { useFirmVisitationState } from './firmVisitationState'
import { toast } from 'react-toastify'
import ContentCopy from '@mui/icons-material/ContentCopy'
type FirmVisitationBannerProps = {}

export const FirmVisitationBanner: FC<FirmVisitationBannerProps> = () => {
    const handleVisitStateChange = useCallback(
        () => window.location.replace('/'),
        []
    )
    const { visitId, setVisitInfo, visitedFirmId } = useFirmVisitationState(
        handleVisitStateChange
    )

    const copyFirmId = () => {
        if (!visitedFirmId) {
            return
        }
        navigator.clipboard.writeText(visitedFirmId)
        toast.success('Firm Id copied')
    }

    if (!visitId) {
        return <></>
    }
    return (
        <Stack direction="row" spacing={0.5}>
            <Alert severity="warning">Firm visitation active</Alert>
            <Button onClick={() => setVisitInfo({ id: null, firmId: null })}>
                Return to home firm
            </Button>
            <Tooltip title="Copy firm id to clipboard">
                <Button onClick={() => copyFirmId()}>
                    {' '}
                    <ContentCopy />
                </Button>
            </Tooltip>
        </Stack>
    )
}
