import { Grid } from '@mui/material'
import React from 'react'
import { InnerTabs } from '../InnerTabs/InnerTabs'
import { DetailsTab } from '../../types/detailsTab'
import { billRateRoles, withRole } from '../withRole'
import {
    doesFirmHavePaymentsAddonHelper,
    useLoadFirmWithAddons,
} from 'src/services/firm'

const linksData: DetailsTab[] = [
    {
        name: 'Invoice Settings',
        link: `/settings/billing`,
        linkRoot: `/settings/billing`,
    },
    {
        name: 'Billing Info',
        link: `/settings/billing/info`,
        linkRoot: `/settings/billing/info`,
    },
]

const BillingRoleOnlyTabs = withRole(billRateRoles)(InnerTabs)

type LayoutProps = React.PropsWithChildren<{}>
export function TabbedSettingsBillingLayout({ children }: LayoutProps) {
    const { firm, isLoading } = useLoadFirmWithAddons()
    var links = linksData
    if (firm && doesFirmHavePaymentsAddonHelper(firm)) {
        links = [
            ...links,
            {
                name: 'Payment Acceptance',
                link: `/settings/billing/payment`,
                linkRoot: `/settings/billing/payment`,
            },
        ]
    }
    return (
        <Grid container>
            <Grid container direction="row">
                <Grid item flexGrow={1}>
                    <Grid container flexDirection="row" gap={1}>
                        <BillingRoleOnlyTabs data={links} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid width="100%">
                <main>{children}</main>
            </Grid>
        </Grid>
    )
}
