/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Site } from '../models/Site';
import type { SiteCreate } from '../models/SiteCreate';
import type { SiteOfficerHistory } from '../models/SiteOfficerHistory';
import type { SiteUpdate } from '../models/SiteUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SiteService {

    /**
     * Create Site
     * @param customerId
     * @param requestBody
     * @returns Site Successful Response
     * @throws ApiError
     */
    public static createSiteApiV1SiteCustomerIdPost(
        customerId: string,
        requestBody: SiteCreate,
    ): CancelablePromise<Site> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/site/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Site
     * Return the site for a customer.
     * @param customerId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static readSiteApiV1SiteCustomerIdGet(
        customerId: string,
    ): CancelablePromise<(Site | null)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/site/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Site
     * @param customerId
     * @param requestBody
     * @returns Site Successful Response
     * @throws ApiError
     */
    public static updateSiteApiV1SiteCustomerIdPut(
        customerId: string,
        requestBody: SiteUpdate,
    ): CancelablePromise<Site> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/site/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Site
     * @param customerId
     * @returns Site Successful Response
     * @throws ApiError
     */
    public static deleteSiteApiV1SiteCustomerIdDelete(
        customerId: string,
    ): CancelablePromise<Site> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/site/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Site Officer History
     * @param customerId
     * @returns SiteOfficerHistory Successful Response
     * @throws ApiError
     */
    public static getSiteOfficerHistoryApiV1SiteCustomerIdSiteOfficerHistoryGet(
        customerId: string,
    ): CancelablePromise<Array<SiteOfficerHistory>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/site/{customer_id}/site_officer_history',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Does Site Have Billing Enabled
     * @param siteId
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static doesSiteHaveBillingEnabledApiV1SiteSiteIdBillingEnabledGet(
        siteId: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/site/{site_id}/billing_enabled',
            path: {
                'site_id': siteId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
