/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CHQTotals } from './CHQTotals';

export type CHQMinimalPayroll = {
    company: string;
    period_start: string;
    period_end: string;
    payday: string;
    id: string;
    funding_payment_method?: string;
    approval_deadline?: string;
    approved_at?: string;
    status?: CHQMinimalPayroll.status;
    type?: string;
    pay_schedule?: string;
    processing_period?: string;
    managed?: boolean;
    totals?: CHQTotals;
    is_void?: boolean;
    metadata?: any;
};

export namespace CHQMinimalPayroll {

    export enum status {
        DRAFT = 'draft',
        PENDING = 'pending',
        PROCESSING = 'processing',
        FAILED = 'failed',
        PARTIALLY_PAID = 'partially_paid',
        PAID = 'paid',
    }


}

