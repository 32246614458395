/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ContractorPayrollDeduction } from './ContractorPayrollDeduction';
import type { ContractorPayrollEarning } from './ContractorPayrollEarning';
import type { ContractorPayrollReimbursement } from './ContractorPayrollReimbursement';
import type { EmployeeOrContractorPayrollOfficer } from './EmployeeOrContractorPayrollOfficer';

export type ContractorPayroll = {
    payroll_id: string;
    id: string;
    officer_id: string;
    officer_name?: string;
    payment_method?: ContractorPayroll.payment_method;
    amount_cents: number;
    reimbursement_amount_cents?: number;
    deduction_amount_cents?: number;
    net_pay_cents?: number;
    regular_amount_cents?: number;
    regular_hours?: number;
    overtime_amount_cents?: number;
    overtime_hours?: number;
    double_overtime_amount_cents?: number;
    double_overtime_hours?: number;
    pto_amount_cents?: number;
    pto_hours?: number;
    sick_amount_cents?: number;
    sick_hours?: number;
    holiday_amount_cents?: number;
    holiday_hours?: number;
    spb_amount_cents?: number;
    other_amount_cents?: number;
    officer: EmployeeOrContractorPayrollOfficer;
    earnings: Array<ContractorPayrollEarning>;
    reimbursements?: Array<ContractorPayrollReimbursement>;
    deductions?: Array<ContractorPayrollDeduction>;
};

export namespace ContractorPayroll {

    export enum payment_method {
        DIRECT_DEPOSIT = 'direct_deposit',
        MANUAL = 'manual',
    }


}

