import { useLoadUpcomingPaySchedule } from 'src/services/payroll'
import { ContentModal } from '../Modal'
import { useRouter } from 'next/router'
import {
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { format, parseISO } from 'date-fns'
type UpcomingPayrollScheduleModalProps = { handleClose: () => void }

export const UpcomingPayrollScheduleModal: React.FC<
    UpcomingPayrollScheduleModalProps
> = ({ handleClose }) => {
    const router = useRouter()
    const payGroupId =
        router.query?.id === 'default' || Array.isArray(router.query?.id)
            ? undefined
            : router.query.id
    const upcoming = useLoadUpcomingPaySchedule(payGroupId)

    const table = upcoming ? (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Payday</TableCell>
                        <TableCell>Period Start</TableCell>
                        <TableCell>Period End</TableCell>
                        <TableCell align="right">Approval Deadline</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {upcoming.map((row) => (
                        <TableRow key={row.payday}>
                            <TableCell>
                                {row.payday}
                                {row.impacted_by_weekend_or_holiday ? '*' : ''}
                            </TableCell>
                            <TableCell>{row.period_start}</TableCell>
                            <TableCell>{row.period_end}</TableCell>
                            <TableCell align="right">
                                {format(
                                    parseISO(row.approval_deadline),
                                    'yyyy-MM-dd'
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Typography variant="caption">
                * Payday impacted by weekend or holiday
            </Typography>
        </TableContainer>
    ) : (
        <Skeleton height={300} />
    )

    return (
        <ContentModal isOpen={true} handleClose={handleClose} showCloseButton>
            <Typography variant="h6">Pay Schedule Paydays</Typography>
            {table}
        </ContentModal>
    )
}
