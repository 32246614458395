/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefaultIndustryCreate } from '../models/DefaultIndustryCreate';
import type { Industry } from '../models/Industry';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class IndustryService {

    /**
     * Read Industries
     * @returns Industry Successful Response
     * @throws ApiError
     */
    public static readIndustriesApiV1IndustryGet(): CancelablePromise<Array<Industry>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/industry',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Default Industry
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static createDefaultIndustryApiV1IndustryDefaultPost(
        requestBody: DefaultIndustryCreate,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/industry/default',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
