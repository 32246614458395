/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ContractorPayrollUpdate = {
    payment_method?: ContractorPayrollUpdate.payment_method;
};

export namespace ContractorPayrollUpdate {

    export enum payment_method {
        DIRECT_DEPOSIT = 'direct_deposit',
        MANUAL = 'manual',
    }


}

